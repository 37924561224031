import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ant-row"
}
const _hoisted_2 = {
  class: "",
  title: "Custo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "vertical" }, {
          default: _withCtx(() => [
            (!_ctx.state.preparandoCustos)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedItem.custos, (custoProps, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "ant-col ant-col-xs-24 ant-col-xl-4"
                    }, [
                      _createVNode(_component_a_form_item, { class: "ss-margin-campos" }, {
                        label: _withCtx(() => [
                          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.obtemDescricaoTipoCusto(custoProps.codigoTipoCusto)), 1),
                          (_ctx.apresentarDetalheCalculoCusto(custoProps.codigoTipoCusto))
                            ? (_openBlock(), _createBlock(_component_a_tooltip, {
                                key: 0,
                                placement: "top",
                                title: _ctx.obtemDetalheCalculoCusto(custoProps)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_icone, {
                                    class: "ss-icone-informacao-campo",
                                    nome: "informacao-campo"
                                  })
                                ]),
                                _: 2
                              }, 1032, ["title"]))
                            : _createCommentVNode("", true)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_campo_numerico, {
                            valor: custoProps.valorCusto,
                            "onUpdate:valor": ($event: any) => ((custoProps.valorCusto) = $event),
                            monetario: true,
                            quantidadeCasasDecimais: custoProps.casasDecimais,
                            disabled: true
                          }, null, 8, ["valor", "onUpdate:valor", "quantidadeCasasDecimais"])
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}

import { defineComponent, onBeforeMount, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { EFiltroPeriodoMovimentoComercial } from '@/models/Enumeradores/Compartilhados/EFiltroPeriodoMovimentoComercial';
import storeSistema from '@/store/storeSistema';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { ICardKanban, ICardKanbanDetalhe, IColunaKanban } from '@/core/models/IKanban';
import Kanban from '@/core/components/Kanban/Kanban.vue';
import RecebimentoMercadoriasModal from './RecebimentoMercadoriasModal.vue';
import ServicoRecebimentoMercadoria from '@/servicos/Compras/ServicoRecebimentoMercadoria';
import SelecionarFiltroPeriodoMovimentoComercial from '@/components/Compartilhados/SelecionarFiltroPeriodoMovimentoComercial.vue';
import SelecionarOrigemRecebimentoMercadoriasModal from './SelecionarOrigemRecebimentoMercadoriasModal.vue';
import { EOrigemRecebimentoMercadoria } from '@/models/Enumeradores/Compras/EOrigemRecebimentoMercadoria';
import { IDTORecebimentoMercadorias } from '@/models/DTO/Compras/IDTORecebimentoMercadorias';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EStatusRecebimentoMercadoria } from '@/models/Enumeradores/Compras/EStatusRecebimentoMercadoria';
import RecebimentoMercadoriasListagem from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasListagem.vue';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IParametrosConsultaRecebimentoMercadoria } from '@/models/ParametrosRequisicao/Compras/IParametrosConsultaRecebimentoMercadoria';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import Card from '@/core/components/Tela/Card.vue';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import { IRecebimentoMercadoria } from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';

export default defineComponent({
  name: 'RecebimentoMercadoriasIndex',
  components: {
    Icone,
    PreviewPdf,
    Card,
    SelecionarDataInicialFinal,
    BuscaGeral,
    Kanban,
    RecebimentoMercadoriasModal,
    SelecionarFiltroPeriodoMovimentoComercial,
    SelecionarOrigemRecebimentoMercadoriasModal,
    RecebimentoMercadoriasListagem,
    TituloPadrao,
  },
  setup(props) {
    const servicoRecebimentoMercadoria = new ServicoRecebimentoMercadoria();
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas,
      adicionarAtalho, removerAtalho, apresentarMensagemAlerta, apresentarMensagemErro, apresentarMensagemSucesso,
    } = useTelaBase();
    let debounce = 0;
    const state = reactive({
      identificadorRecurso: 'RECEBIMENTOS_MERCADORIAS',
      identificadorPermissao: 'PER_RECEBIMENTOS_MERCADORIAS',
      empresaSelecionada: 0,
      origemSelecionada: 0,
      dataAtual: '',
      filtroPeriodo: 4,
      dataInicialSelecionada: '',
      dataFinalSelecionada: '',
      buscaRapida: '',
      colunas: [] as IColunaKanban[],
      filtros: [] as IFiltroGenerico[],
      selecionados: [] as number[],
      paginacao: {} as IPagination,
      cardSelecionado: '',
      recebimentos: [] as IDTORecebimentoMercadorias[],
      buscaAvancada: {} as IBuscaAvancada,
      exibirLancamento: false,
      exibirSelecionarOrigem: false,
      operacaoLancamento: {} as ITelaOperacao,
      qtdSelecionados: 0,
      qtdSelecionadosConcluir: 0,
      qtdSelecionadosRetomar: 0,
      qtdSelecionadosCancelar: 0,
      qtdSelecionadosExcluir: 0,
      visualizarKanban: true,
      atualizandoCards: false,
      recebimentoMercadoriaImportacao: {} as IRecebimentoMercadoria,
      arquivosPdf: [] as IArquivoPdf[],
    });

    function atualizarQtdSelecionados() {
      state.qtdSelecionados = 0;
      state.qtdSelecionadosConcluir = 0;
      state.qtdSelecionadosCancelar = 0;
      state.qtdSelecionadosExcluir = 0;
      state.qtdSelecionadosRetomar = 0;
      state.colunas.forEach((c) => {
        if (c.qtdSelecionados !== undefined) {
          state.qtdSelecionados += c.qtdSelecionados;
          if (Number(c.identificador) === 1) {
            state.qtdSelecionadosExcluir += c.qtdSelecionados;
          }
          if (Number(c.identificador) < 5) {
            state.qtdSelecionadosConcluir += c.qtdSelecionados;
          }
          if (Number(c.identificador) < 6) {
            state.qtdSelecionadosCancelar += c.qtdSelecionados;
          }
          if (Number(c.identificador) > 1 && Number(c.identificador) < 6) {
            state.qtdSelecionadosRetomar += c.qtdSelecionados;
          }
        }
      });
    }

    function atualizarSelecionadosKanban() {
      state.colunas.forEach((c, index) => {
        state.colunas[index].qtdSelecionados = 0;
      });

      state.recebimentos.forEach((r) => {
        const indexColuna = state.colunas.findIndex((c) => c.identificador === String(r.status));
        const indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(r.codigo));
        const indexSelecionados = state.selecionados.findIndex((s) => s === r.codigo);
        if (indexSelecionados >= 0) state.colunas[indexColuna].qtdSelecionados += 1;
        state.colunas[indexColuna].cards[indexCard].selecionado = indexSelecionados >= 0;
      });
      atualizarQtdSelecionados();
    }

    function atualizarSelecionados(identificador: string) {
      atualizarQtdSelecionados();
      const indexSelecionados = state.selecionados.findIndex((s) => s === Number(identificador));
      if (indexSelecionados < 0) {
        state.selecionados.push(Number(identificador));
      } else {
        state.selecionados.splice(indexSelecionados, 1);
      }
    }

    function desmarcarRecebimentoSelecionado(codigoRecebimento: number, status: number, statusNovo: number) {
      const indexSelecionados = state.selecionados.findIndex((s) => s === codigoRecebimento);
      if (indexSelecionados >= 0) {
        let indexColuna = state.colunas.findIndex((c) => c.identificador === String(status));
        let indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(codigoRecebimento));
        if (indexCard === -1) {
          indexColuna = state.colunas.findIndex((c) => c.identificador === String(statusNovo));
          indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(codigoRecebimento));
        }
        if (state.colunas[indexColuna].qtdSelecionados > 0) state.colunas[indexColuna].qtdSelecionados -= 1;
        state.colunas[indexColuna].cards[indexCard].selecionado = false;
        state.selecionados.splice(indexSelecionados, 1);
        atualizarQtdSelecionados();
      }
    }

    function preencherColunas() {
      state.colunas = [] as IColunaKanban[];
      state.colunas[0] = {} as IColunaKanban;
      state.colunas[0].identificador = '1';
      state.colunas[0].titulo = 'Em digitação';
      state.colunas[0].cor = '#969696';
      state.colunas[0].cards = [] as ICardKanban[];

      state.colunas[1] = {} as IColunaKanban;
      state.colunas[1].identificador = '2';
      state.colunas[1].titulo = 'Aguardando conferência';
      state.colunas[1].cor = '#d6c720';
      state.colunas[1].cards = [] as ICardKanban[];

      state.colunas[2] = {} as IColunaKanban;
      state.colunas[2].identificador = '3';
      state.colunas[2].titulo = 'Conferência em andamento';
      state.colunas[2].cor = '#f54e1b';
      state.colunas[2].cards = [] as ICardKanban[];

      state.colunas[3] = {} as IColunaKanban;
      state.colunas[3].identificador = '4';
      state.colunas[3].titulo = 'Conferidos';
      state.colunas[3].cor = '#25b818';
      state.colunas[3].cards = [] as ICardKanban[];

      state.colunas[4] = {} as IColunaKanban;
      state.colunas[4].identificador = '5';
      state.colunas[4].titulo = 'Concluídos';
      state.colunas[4].cor = '#1843b8';
      state.colunas[4].cards = [] as ICardKanban[];

      state.colunas[5] = {} as IColunaKanban;
      state.colunas[5].identificador = '7';
      state.colunas[5].titulo = 'Cancelados';
      state.colunas[5].cor = '#cf2751';
      state.colunas[5].cards = [] as ICardKanban[];
    }

    function limparCards() {
      state.colunas.forEach((c, index) => {
        state.colunas[index].qtdSelecionados = 0;
        state.colunas[index].cards = [] as ICardKanban[];
      });
    }

    function criarCard(indexColuna: number, recebimento: IDTORecebimentoMercadorias) {
      const card = {} as ICardKanban;
      const indexSelecionados = state.selecionados.findIndex((s) => s === recebimento.codigo);

      card.identificadorColuna = state.colunas[indexColuna].identificador;
      card.identificador = String(recebimento.codigo);
      card.titulo = `Nº: ${recebimento.numero}`;
      card.ordem = recebimento.ordem;
      card.selecionado = indexSelecionados >= 0;
      card.detalhes = [] as ICardKanbanDetalhe[];
      let indexDetalhe = 0;
      if (recebimento.numeroNotaFiscal > 0) {
        card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
        card.detalhes[indexDetalhe].titulo = 'Nota Fiscal:';
        card.detalhes[indexDetalhe].detalhe = recebimento.numeroNotaFiscal.toString();
        indexDetalhe += 1;
      }
      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Data de Emissão: ';
      card.detalhes[indexDetalhe].detalhe = UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(recebimento.dataEmissao);
      indexDetalhe += 1;

      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Fornecedor: ';
      card.detalhes[indexDetalhe].detalhe = recebimento.nomeRazaoSocialEmitente.length > 29 ? recebimento.nomeRazaoSocialEmitente.substring(0, 30) : recebimento.nomeRazaoSocialEmitente;

      indexDetalhe += 1;
      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Total R$: ';
      card.detalhes[indexDetalhe].detalhe = UtilitarioMascara.mascararValor(recebimento.totalNotaFiscal, 2);

      state.colunas[indexColuna].cards.push(card);
      if (indexSelecionados >= 0) state.colunas[indexColuna].qtdSelecionados += 1;
    }

    function prencheDatasPeriodo() {
      switch (state.filtroPeriodo) {
        case EFiltroPeriodoMovimentoComercial.Hoje:
          state.dataInicialSelecionada = state.dataAtual;
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ontem:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          state.dataFinalSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          break;

        case EFiltroPeriodoMovimentoComercial.SemanaAtual:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 7);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.MesAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaMes(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaMes(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.AnoAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaAno(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaAno(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos15Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 15);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos30Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 30);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos60Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 60);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos90dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 90);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        default:
      }
    }

    async function buscarRecebimentos(ordenacao: IOrdenacao[] = []) {
      state.recebimentos = [];
      limparCards();
      state.filtros = [] as IFiltroGenerico[];
      state.buscaAvancada.filtrosAdicionados.forEach((item) => {
        state.filtros.push(item.filtro);
      });

      prencheDatasPeriodo();
      const parametrosConsulta = {} as IParametrosConsultaRecebimentoMercadoria;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.periodo = state.filtroPeriodo;
      parametrosConsulta.dataInicial = state.dataInicialSelecionada;
      parametrosConsulta.dataFinal = state.dataFinalSelecionada;
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      if (UtilitarioGeral.validaLista(ordenacao) && !state.visualizarKanban) {
        ordenacao.forEach((item) => {
          parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });
      } else {
        parametrosConsulta.ordenacao.push('Status', 'Ordem');
      }

      parametrosConsulta.qtdeRegistrosPagina = 1000;

      telaBase.carregando = true;
      const listaPaginada = await servicoRecebimentoMercadoria.buscaAvancada(parametrosConsulta, state.filtros);
      state.recebimentos = listaPaginada.dados;
      state.recebimentos.forEach((r) => {
        criarCard(state.colunas.findIndex((c) => c.identificador === String(r.status)), r);
      });
      telaBase.carregando = false;
    }

    async function atualizarOrdemCards(ordenacaoRegistros: IOrdenacaoRegistro[]) {
      await servicoRecebimentoMercadoria.atualizarOrdenacao(ordenacaoRegistros, state.empresaSelecionada);
    }

    function recriarColuna(identificador: string) {
      const index = state.colunas.findIndex((c) => c.identificador === identificador);

      state.colunas[index].cards = [] as ICardKanban[];
      state.colunas[index].qtdSelecionados = 0;
      state.recebimentos.forEach((r) => {
        if (r.status === Number(identificador)) {
          criarCard(index, r);
        }
      });
      atualizarQtdSelecionados();
    }

    function obterTituloColuna(identificador: string) {
      const index = state.colunas.findIndex((c) => c.identificador === identificador);
      return state.colunas[index].titulo;
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.empresaSelecionada);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      await buscarRecebimentos();
    }

    function selecionarOrigem() {
      state.exibirSelecionarOrigem = true;
    }

    function selecionarCard(selecionado: string) {
      state.cardSelecionado = selecionado;
    }

    function incluir(origem: EOrigemRecebimentoMercadoria) {
      if (origem === EOrigemRecebimentoMercadoria.Manual) {
        state.recebimentoMercadoriaImportacao = {} as IRecebimentoMercadoria;
      }
      state.origemSelecionada = origem;
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function incluirRecebimentoImportado(recebimentoMercadoria:IRecebimentoMercadoria) {
      state.recebimentoMercadoriaImportacao = recebimentoMercadoria;
      incluir(recebimentoMercadoria.origem);
    }

    function editarRecebimento(codigoRecebimento: number) {
      state.recebimentoMercadoriaImportacao = {} as IRecebimentoMercadoria;
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Alterar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = codigoRecebimento;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function clickEditar() {
      editarRecebimento(Number(state.cardSelecionado));
    }

    async function alterarEtapa(recebimento: IDTORecebimentoMercadorias, novaEtapa: string) {
      let retorno = {} as IRetornoRequisicao;
      const statusAntigo = String(recebimento.status);
      const index = state.recebimentos.findIndex((r) => r.codigo === recebimento.codigo);
      desmarcarRecebimentoSelecionado(state.recebimentos[index].codigo, state.recebimentos[index].status, Number(novaEtapa));
      retorno = await servicoRecebimentoMercadoria.alterarEtapa(recebimento.codigo, recebimento.codigoEmpresa, Number(novaEtapa));
      if (retorno.status === 1) {
        state.recebimentos[index].status = Number(novaEtapa);
      } else if (retorno.status === 2) {
        apresentarMensagemAlerta(retorno.mensagem);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
      if (state.visualizarKanban) {
        recriarColuna(novaEtapa);
        recriarColuna(statusAntigo);
      } else {
        buscarRecebimentos();
      }
    }

    async function alterarEtapaVarios(recebimentos: IDTORecebimentoMercadorias[], proximaEtapa: boolean, cancelamento: boolean) {
      let retorno = {} as IRetornoRequisicao;
      const colunasAlteradas = [] as string[];
      const codigosRecebimentos = [] as number[];

      recebimentos.forEach((recebimento) => {
        let novaEtapa = 0;

        if (cancelamento) {
          novaEtapa = 7;
        } else if (proximaEtapa) {
          novaEtapa = recebimento.status + 1;
        } else {
          novaEtapa = recebimento.status - 1;
        }

        if (!colunasAlteradas.some((c) => c === String(recebimento.status))) {
          colunasAlteradas.push(String(recebimento.status));
        }
        if (!colunasAlteradas.some((c) => c === String(novaEtapa))) {
          colunasAlteradas.push(String(novaEtapa));
        }
        desmarcarRecebimentoSelecionado(recebimento.codigo, recebimento.status, Number(novaEtapa));
        codigosRecebimentos.push(recebimento.codigo);
      });
      retorno = await servicoRecebimentoMercadoria.alterarEtapaVarios(state.empresaSelecionada, proximaEtapa, cancelamento, codigosRecebimentos);
      if (retorno.status === 1) {
        state.recebimentos.forEach((r, index) => {
          if (cancelamento) {
            state.recebimentos[index].status = 7;
          } else if (proximaEtapa) {
            state.recebimentos[index].status += 1;
          } else {
            state.recebimentos[index].status -= 1;
          }
        });
      } else if (retorno.status === 2) {
        apresentarMensagemAlerta(retorno.mensagem);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
      if (state.visualizarKanban) {
        colunasAlteradas.forEach((c) => {
          recriarColuna(c);
        });
      } else {
        buscarRecebimentos();
      }
    }

    async function moverCard(card: ICardKanban, identificadorNovaColuna: string) {
      state.cardSelecionado = card.identificador;
      const indexRecebimento = state.recebimentos.findIndex((r) => r.codigo === Number(state.cardSelecionado));
      const recebimento = state.recebimentos[indexRecebimento];
      if (recebimento.status === EStatusRecebimentoMercadoria.Cancelado) {
        apresentarMensagemAlerta('O recebimento informado está cancelado.');
        recriarColuna(identificadorNovaColuna);
        recriarColuna(String(recebimento.status));
        return;
      }
      Modal.confirm({
        title: `Realmente deseja passar da etapa ${obterTituloColuna(String(recebimento.status))} para a etapa ${obterTituloColuna(identificadorNovaColuna)}?`,
        content: `Recebimento ${recebimento.numero} Nota ${recebimento.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(recebimento.dataEmissao)}.`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          await alterarEtapa(recebimento, identificadorNovaColuna);
        },
        onCancel: () => {
          recriarColuna(identificadorNovaColuna);
          recriarColuna(String(recebimento.status));
        },
      });
    }

    async function concluirEtapa() {
      let recebimento = {} as IDTORecebimentoMercadorias;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosConcluir === 0) {
        apresentarMensagemAlerta('Nenhum recebimento selecionado para conclusão de etapa.');
        return;
      } if (state.qtdSelecionadosConcluir === 1) {
        state.selecionados.forEach((s) => {
          const index = state.recebimentos.findIndex((r) => r.codigo === s);
          if (state.recebimentos[index].status < 5) {
            recebimento = state.recebimentos[index];
          }
        });
        titulo = `Realmente deseja passar da etapa ${obterTituloColuna(String(recebimento.status))} para a etapa ${obterTituloColuna(String(recebimento.status + 1))}?`;
        mensagem = `Recebimento ${recebimento.numero} Nota ${recebimento.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(recebimento.dataEmissao)}.`;
      } else {
        titulo = `Realmente deseja alterar a etapa dos ${state.qtdSelecionadosConcluir} recebimentos selecionados?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosConcluir === 1) {
            await alterarEtapa(recebimento, String(recebimento.status + 1));
          } else {
            const recebimentos = [] as IDTORecebimentoMercadorias[];
            state.selecionados.forEach((s) => {
              const indexRecebimento = state.recebimentos.findIndex((r) => r.codigo === Number(s));
              if (state.recebimentos[indexRecebimento].status < 5) {
                recebimentos.push(state.recebimentos[indexRecebimento]);
              }
            });
            await alterarEtapaVarios(recebimentos, true, false);
          }
        },
      });
    }

    async function retornarEtapa() {
      let recebimento = {} as IDTORecebimentoMercadorias;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosRetomar === 0) {
        apresentarMensagemAlerta('Nenhum recebimento selecionado para retornar a etapa.');
        return;
      } if (state.qtdSelecionadosRetomar === 1) {
        state.selecionados.forEach((s) => {
          const index = state.recebimentos.findIndex((r) => r.codigo === s);
          if (state.recebimentos[index].status > 1 && state.recebimentos[index].status < 6) {
            recebimento = state.recebimentos[index];
          }
        });
        titulo = `Realmente deseja passar da etapa ${obterTituloColuna(String(recebimento.status))} para a etapa ${obterTituloColuna(String(recebimento.status - 1))}?`;
        mensagem = `Recebimento ${recebimento.numero} Nota ${recebimento.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(recebimento.dataEmissao)}.`;
      } else {
        titulo = `Realmente deseja alterar a etapa dos ${state.qtdSelecionadosConcluir} recebimentos selecionados?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosRetomar === 1) {
            await alterarEtapa(recebimento, String(recebimento.status - 1));
          } else {
            const recebimentos = [] as IDTORecebimentoMercadorias[];
            state.selecionados.forEach((s) => {
              const indexRecebimento = state.recebimentos.findIndex((r) => r.codigo === Number(s));
              if (state.recebimentos[indexRecebimento].status > 1 && state.recebimentos[indexRecebimento].status < 6) {
                recebimentos.push(state.recebimentos[indexRecebimento]);
              }
            });
            await alterarEtapaVarios(recebimentos, false, false);
          }
        },
      });
    }

    async function cancelar() {
      let recebimento = {} as IDTORecebimentoMercadorias;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosCancelar === 0) {
        apresentarMensagemAlerta('Nenhum recebimento selecionado para o cancelamento.');
        return;
      } if (state.qtdSelecionadosCancelar === 1) {
        state.selecionados.forEach((s) => {
          const index = state.recebimentos.findIndex((r) => r.codigo === s);
          if (state.recebimentos[index].status < 6) {
            recebimento = state.recebimentos[index];
          }
        });
        titulo = 'Realmente deseja cancelar o recebimento?';
        mensagem = `Recebimento ${recebimento.numero} Nota ${recebimento.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(recebimento.dataEmissao)}.`;
      } else {
        titulo = `Realmente deseja cancelar os ${state.qtdSelecionadosConcluir} recebimentos selecionados?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosCancelar === 1) {
            await alterarEtapa(recebimento, '7');
          } else {
            const recebimentos = [] as IDTORecebimentoMercadorias[];
            state.selecionados.forEach((s) => {
              const indexRecebimento = state.recebimentos.findIndex((r) => r.codigo === Number(s));
              if (state.recebimentos[indexRecebimento].status < 6) {
                recebimentos.push(state.recebimentos[indexRecebimento]);
              }
            });
            await alterarEtapaVarios(recebimentos, false, true);
          }
        },
      });
    }

    async function excluirRecebimentos(recebimento: IDTORecebimentoMercadorias[]) {
      const colunasAlteradas = [] as string[];
      const codigosRecebimentos = [] as number[];

      recebimento.forEach((recebimento) => {
        desmarcarRecebimentoSelecionado(recebimento.codigo, recebimento.status, recebimento.status);
        if (!colunasAlteradas.some((c) => c === String(recebimento.status))) {
          colunasAlteradas.push(String(recebimento.status));
        }
        codigosRecebimentos.push(recebimento.codigo);
      });

      const retorno = await servicoRecebimentoMercadoria.excluirVarios(state.empresaSelecionada, codigosRecebimentos);
      if (retorno.status === 1) {
        recebimento.forEach((recebimento) => {
          const indexRecebimento = state.recebimentos.findIndex((r) => r.codigo === recebimento.codigo);
          state.recebimentos.splice(indexRecebimento, 1);
        });
        colunasAlteradas.forEach((c) => {
          recriarColuna(c);
        });
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function excluir() {
      const recebimentos = [] as IDTORecebimentoMercadorias[];
      let recebimento = {} as IDTORecebimentoMercadorias;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosExcluir === 0) {
        apresentarMensagemAlerta('Nenhum recebimento selecionado para exclusão.');
        return;
      } if (state.qtdSelecionadosExcluir === 1) {
        state.selecionados.forEach((s) => {
          const index = state.recebimentos.findIndex((r) => r.codigo === s);
          if (state.recebimentos[index].status === 1) {
            recebimento = state.recebimentos[index];
          }
        });
        titulo = 'Realmente deseja excluir o recebimento?';
        mensagem = `Recebimento ${recebimento.numero} Nota ${recebimento.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(recebimento.dataEmissao)}.`;
      } else {
        titulo = `Realmente deseja excluir os ${state.qtdSelecionadosExcluir} recebimentos selecionados?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosExcluir === 1) {
            recebimentos.push(recebimento);
            await excluirRecebimentos(recebimentos);
          } else {
            state.selecionados.forEach((s) => {
              const indexRecebimento = state.recebimentos.findIndex((r) => r.codigo === Number(s));
              if (state.recebimentos[indexRecebimento].status === 1) {
                recebimentos.push(state.recebimentos[indexRecebimento]);
              }
            });
            await excluirRecebimentos(recebimentos);
          }
        },
      });
    }

    async function imprimirDetalhado(codgo: number) {
      telaBase.carregando = true;
      const retornoRelatorio = await servicoRecebimentoMercadoria.imprimirRecebimento(codgo, state.empresaSelecionada, ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function imprimirListagem(codigos: number[]) {
      const parametrosConsulta = {} as IParametrosConsultaRecebimentoMercadoria;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.numeroPagina = 1;
      parametrosConsulta.qtdeRegistrosPagina = 0;
      parametrosConsulta.qtdeRegistrosTotal = 0;
      parametrosConsulta.periodo = state.filtroPeriodo;
      parametrosConsulta.dataInicial = state.dataInicialSelecionada;
      parametrosConsulta.dataFinal = state.dataFinalSelecionada;
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.ordenacao.push('Status', 'Ordem');

      telaBase.carregando = true;
      const retornoRelatorio = await servicoRecebimentoMercadoria.relatorioPadrao(ETipoArquivo.PDF, parametrosConsulta, state.filtros, codigos);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function imprimir() {
      if (state.selecionados.length === 0) {
        apresentarMensagemAlerta('Nenhum recebimento selecionado para impressão!');
      } else if (state.selecionados.length === 1) {
        imprimirDetalhado(state.selecionados[0]);
      } else {
        imprimirListagem(state.selecionados);
      }
    }

    onBeforeMount(async () => {
      state.visualizarKanban = !UtilitarioDispositivo.larguraTelaMobile();
      state.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      telaBase.identificadorRecurso = state.identificadorRecurso;
      telaBase.identificadorPermissao = state.identificadorPermissao;
      preencherColunas();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([state.empresaSelecionada]);
      telaBase.propriedadesConsulta = await servicoRecebimentoMercadoria.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      state.dataAtual = await new ServicoSistema().obterDataAtual();
      await buscarRecebimentos();
    });

    function buscaRapidaRecibmento() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarRecebimentos();
      }, 600);
    }

    async function periodoAlterado() {
      if (state.filtroPeriodo !== EFiltroPeriodoMovimentoComercial.Personalizado) {
        await buscarRecebimentos();
      }
    }
    return {
      state,
      props,
      window,
      EVinculoEmpresa,
      EFiltroPeriodoMovimentoComercial,
      UtilitarioMascara,
      UtilitarioData,
      storeSistema,
      telaBase,
      buscaRapidaRecibmento,
      incluir,
      editarRecebimento,
      clickEditar,
      excluir,
      retornarEtapa,
      buscarRecebimentos,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      selecionarCard,
      concluirEtapa,
      moverCard,
      selecionarOrigem,
      atualizarSelecionados,
      atualizarOrdemCards,
      atualizarSelecionadosKanban,
      cancelar,
      imprimirDetalhado,
      imprimir,
      periodoAlterado,
      incluirRecebimentoImportado,
    };
  },
});


import {
  defineComponent, reactive, watch, inject,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IRecebimentoMercadoriaItem, IRecebimentoMercadoriaItemPreco } from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import SelecionarUnidade from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidade.vue';
import SelecionarUnidadeProdutoMovimento from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidadeProdutoMovimento.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarCfop from '@/components/Cadastros/Tributacoes/Cfops/SelecionarCfop.vue';
import NotaFiscalItemDetalhe from '@/components/Fiscal/NotasFiscais/NotaFiscalItemDetalhe.vue';
import NotaFiscalItemImpostos from '@/components/Fiscal/NotasFiscais/NotaFiscalItemImpostos.vue';
import NotaFiscalItemEstoques from '@/components/Fiscal/NotasFiscais/NotaFiscalItemEstoques.vue';
import RecebimentoMercadoriasItemCusto from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasItemCusto.vue';
import NotaFiscalItemDadosEspecificos from '@/components/Fiscal/NotasFiscais/NotaFiscalItemDadosEspecificos.vue';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import ServicoCfop from '@/servicos/Cadastros/Tributacoes/ServicoCfop';
import {
  INotaFiscalIncidenciaCalculo, INotaFiscalItem, INotaFiscalItemCombustivel, INotaFiscalItemEstoque, INotaFiscalItemImposto, INotaFiscalItemImpostoCofins, INotaFiscalItemImpostoIcms, INotaFiscalItemImpostoImportacao, INotaFiscalItemImpostoIpi, INotaFiscalItemImpostoPis, INotaFiscalItemImpostoSubstituicaoTributaria, INotaFiscalItemMedicamento,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import { IDTOUnidadeProdutoMovimento } from '@/models/DTO/Cadastros/Produtos/IDTOUnidadeProdutoMovimento';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { EOrigemValorItem } from '@/models/Enumeradores/EOrigemValorItem';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';

export default defineComponent({
  name: 'RecebimentoMercadoriasItemModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    pessoa: {
      type: Number,
      required: true,
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    estoquesDisponiveis: {
      type: Array as () => IEstoque[],
      required: true,
    },
    item: {
      type: Object as () => IRecebimentoMercadoriaItem,
      required: true,
    },
    totalNota: {
      type: Number,
      required: true,
    },
    existeProximo: {
      type: Boolean,
      required: true,
    },
    existeAnterior: {
      type: Boolean,
      required: true,
    },
    tiposCustosCadastrados: {
      type: Array as () => ITipoCusto[],
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarProduto,
    SelecionarSimNao,
    SelecionarUnidade,
    SelecionarUnidadeProdutoMovimento,
    CampoNumerico,
    SelecionarCfop,
    NotaFiscalItemDetalhe,
    NotaFiscalItemImpostos,
    NotaFiscalItemEstoques,
    RecebimentoMercadoriasItemCusto,
    NotaFiscalItemDadosEspecificos,
  },
  emits: ['update:visivel', 'salvar', 'proximoItem', 'itemAnterior'],
  setup(props, { emit }) {
    const servicoEstoque = new ServicoEstoque();
    const servicoCfop = new ServicoCfop();
    const servicoCalculosComerciais = new ServicoCalculosComerciais();
    const servicoProduto = new ServicoProduto();
    servicoEstoque.requisicaoSistema();
    servicoCfop.requisicaoSistema();

    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      item: {} as IRecebimentoMercadoriaItem,
      tabelasPreco: [] as number[],
      estoquesDisponiveis: [] as number[],
      qtdCasasDecimaisCusto: 2,
      carregandoCfopEntrada: false,
      calculandoCusto: false,
      totalNota: 0,
      telaMobile: false,
    });
    const incidenciasCalculo = inject('RMERCADORIA_INCIDENCIA_CALCULO') as INotaFiscalIncidenciaCalculo[];

    function instanciaNovoItem():IRecebimentoMercadoriaItem {
      const itemRecebimentoMercadoria = {} as IRecebimentoMercadoriaItem;
      itemRecebimentoMercadoria.precos = [] as IRecebimentoMercadoriaItemPreco[];
      itemRecebimentoMercadoria.itemNotaFiscal = {} as INotaFiscalItem;
      itemRecebimentoMercadoria.itemNotaFiscal.estoquesDisponiveis = [] as IEstoque[];
      itemRecebimentoMercadoria.itemNotaFiscal.codigoProdutoDefinicao = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.dadosUnidadeSelecionada = {} as IDTOUnidadeProdutoMovimento;
      itemRecebimentoMercadoria.itemNotaFiscal.dadosUnidadeSelecionada.codigo = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.casasDecimaisUnidade = 2;
      itemRecebimentoMercadoria.itemNotaFiscal.fatorConversao = 1;
      itemRecebimentoMercadoria.itemNotaFiscal.siglaUnidade = '';
      itemRecebimentoMercadoria.itemNotaFiscal.movimentarEstoque = true;
      itemRecebimentoMercadoria.itemNotaFiscal.compoeTotal = true;
      itemRecebimentoMercadoria.itemNotaFiscal.origemFrete = EOrigemValorItem.Rateio;
      itemRecebimentoMercadoria.itemNotaFiscal.origemOutrasDespesas = EOrigemValorItem.Rateio;
      itemRecebimentoMercadoria.itemNotaFiscal.origemSeguro = EOrigemValorItem.Rateio;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos = {} as INotaFiscalItemImposto;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.icms = {} as INotaFiscalItemImpostoIcms;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cst = '00';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.origemMercadoria = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.icms.modalidadeBaseCalculoIcms = 3;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.icms.valorIcms = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi = {} as INotaFiscalItemImpostoIpi;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi.cstIpi = '52';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi.tipoCalculoIpi = 1;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi.codigoEnquadramentoIpi = '999';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi.valorIpi = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.pis = {} as INotaFiscalItemImpostoPis;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.pis.cstPis = '07';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.pis.tipoCalculoPis = 1;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.pis.valorPis = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cofins = {} as INotaFiscalItemImpostoCofins;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cofins.cstCofins = '07';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cofins.tipoCalculoCofins = 1;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cofins.valorCofins = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.st = {} as INotaFiscalItemImpostoSubstituicaoTributaria;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.importacao = {} as INotaFiscalItemImpostoImportacao;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.importacao.valorImportacao = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.importacao.valorIof = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.importacao.despesasAduaneiras = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.medicamento = {} as INotaFiscalItemMedicamento;
      itemRecebimentoMercadoria.itemNotaFiscal.combustivel = {} as INotaFiscalItemCombustivel;
      itemRecebimentoMercadoria.itemNotaFiscal.estoques = [] as INotaFiscalItemEstoque[];
      return itemRecebimentoMercadoria;
    }

    function limparCampos() {
      state.item = instanciaNovoItem();
      state.tabelasPreco = [] as number[];
      state.estoquesDisponiveis = [] as number[];
      state.qtdCasasDecimaisCusto = 2;
      state.carregandoCfopEntrada = false;
      state.calculandoCusto = false;
      state.totalNota = 0;
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
    }

    async function obterEstoquesDisponiveis() {
      const estoquesDisponiveis = await servicoProduto.PreencherEstoquesProduto(state.item.itemNotaFiscal.codigoProdutoDefinicao, props.empresa, props.estoquesDisponiveis);

      if (estoquesDisponiveis.length > 0) {
        state.item.itemNotaFiscal.estoquesDisponiveis = estoquesDisponiveis;
        state.item.itemNotaFiscal.codigosEstoquesDisponiveis = [] as number[];
        state.item.itemNotaFiscal.estoquesDisponiveis.forEach((e) => {
          state.item.itemNotaFiscal.codigosEstoquesDisponiveis.push(e.codigo);
        });
        if (estoquesDisponiveis.length === 1 && (!UtilitarioGeral.validaLista(state.item.itemNotaFiscal.estoques) || state.item.itemNotaFiscal.estoques[0].codigo !== estoquesDisponiveis[0].codigo)) {
          state.item.itemNotaFiscal.estoques = [] as INotaFiscalItemEstoque[];
          const estoqueItem = {} as INotaFiscalItemEstoque;
          estoqueItem.codigoEmpresa = props.empresa;
          estoqueItem.codigoEstoque = estoquesDisponiveis[0].codigo;
          estoqueItem.codigoCentroCusto = estoquesDisponiveis[0].codigoCentroCustoPadrao;
          estoqueItem.codigoNotaFiscalItem = state.item.itemNotaFiscal.codigoNotaFiscal;
          estoqueItem.quantidade = state.item.itemNotaFiscal.quantidade;
          state.item.itemNotaFiscal.estoques.push(estoqueItem);
        }
      }
    }

    function calcularTotalNota() {
      state.totalNota = props.totalNota;
      if (props.item.itemNotaFiscal.compoeTotal && state.item.codigo !== undefined) {
        state.totalNota -= props.item.itemNotaFiscal.valorTotal;
      }

      if (state.item.itemNotaFiscal.compoeTotal) {
        state.totalNota += state.item.itemNotaFiscal.valorTotal;
      }
    }

    async function calcularCusto() {
      if (state.calculandoCusto) return;
      state.calculandoCusto = true;

      const retornosCalculoCusto = await servicoCalculosComerciais.calcularCustoItemRecebimento(state.item, props.tiposCustosCadastrados);

      if (UtilitarioGeral.validaLista(retornosCalculoCusto)) {
        state.item.retornoCalculosCustos = retornosCalculoCusto;
        retornosCalculoCusto.forEach((calculoTipoCusto) => {
          if (calculoTipoCusto.sucesso) {
            const index = state.item.custos.findIndex((c) => c.codigoTipoCusto === calculoTipoCusto.codigoTipoCusto);
            if (index >= 0) {
              state.item.custos[index].valorCusto = calculoTipoCusto.valorCusto;
            }
          }
        });
      } else {
        state.item.retornoCalculosCustos = [];
      }

      state.calculandoCusto = false;
    }

    function validarImpostos() {
      if (state.item.itemNotaFiscal.impostos.cst === undefined || state.item.itemNotaFiscal.impostos.cst === '') {
        apresentarMensagemAlerta('O CST do ICMS deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.impostos.ipi.cstIpi === undefined || state.item.itemNotaFiscal.impostos.ipi.cstIpi === '') {
        apresentarMensagemAlerta('O CST do IPI deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.impostos.pis.cstPis === undefined || state.item.itemNotaFiscal.impostos.pis.cstPis === '') {
        apresentarMensagemAlerta('O CST do PIS deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.impostos.cofins.cstCofins === undefined || state.item.itemNotaFiscal.impostos.cofins.cstCofins === '') {
        apresentarMensagemAlerta('O CST do COFINS deve ser informado!');
        return false;
      }
      return true;
    }

    function validarEstoque() {
      if (!state.item.itemNotaFiscal.movimentarEstoque) return true;

      if (!UtilitarioGeral.validaLista(state.item.itemNotaFiscal.estoques)) {
        apresentarMensagemAlerta('Pelo menos um item de estoque deve ser informado!');
        return false;
      }
      let estoqueSemPreencher = false;

      let qtdTotal = 0;
      state.item.itemNotaFiscal.estoques.forEach((e) => {
        qtdTotal += e.quantidade;
        if (!UtilitarioGeral.validaCodigo(e.codigoEstoque)) {
          estoqueSemPreencher = true;
        }
      });

      if (estoqueSemPreencher) {
        apresentarMensagemAlerta('É necessário selecionar o estoque do produto.');
        return false;
      }

      if (state.item.itemNotaFiscal.quantidade !== qtdTotal) {
        apresentarMensagemAlerta('A quantidade total dos estoques está diferente da quantidade do item!');
        return false;
      }
      return true;
    }

    function validarPreco() {
      return true;
    }

    function validarCampos() {
      if (state.item.itemNotaFiscal.codigoProdutoDefinicao === undefined || state.item.itemNotaFiscal.codigoProdutoDefinicao === 0) {
        apresentarMensagemAlerta('O Produto deve ser informado!');
        return false;
      }

      if (state.item.codigoUnidadeNfFornecedor === undefined || state.item.codigoUnidadeNfFornecedor === 0) {
        apresentarMensagemAlerta('A Unidade no Forcenedor deve ser informada!');
        return false;
      }

      if (state.item.itemNotaFiscal.codigoUnidade === undefined || state.item.itemNotaFiscal.codigoUnidade === 0) {
        apresentarMensagemAlerta('A Unidade deve ser informada!');
        return false;
      }

      if (state.item.quantidadeNfFornecedor === undefined || state.item.quantidadeNfFornecedor < 1) {
        apresentarMensagemAlerta('A Quantidade no fornecedor deve ser informada!');
        return false;
      }

      if (state.item.itemNotaFiscal.quantidade === undefined || state.item.itemNotaFiscal.quantidade < 1) {
        apresentarMensagemAlerta('A Quantidade deve ser informada!');
        return false;
      }

      if (state.item.valorTotalNfFornecedor === undefined || state.item.valorTotalNfFornecedor < 1) {
        apresentarMensagemAlerta('O Total do Item no fornecedor deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.valorTotal === undefined || state.item.itemNotaFiscal.valorTotal < 1) {
        apresentarMensagemAlerta('O Total do Item deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.valorTotal === undefined || state.item.itemNotaFiscal.valorTotal < 1) {
        apresentarMensagemAlerta('O Total do Item deve ser informado!');
        return false;
      }

      if (state.item.cfopNfFornecedor === undefined || state.item.cfopNfFornecedor === 0) {
        apresentarMensagemAlerta('O CFOP no fornecedor deve ser informado!');
        return false;
      }

      if (state.item.itemNotaFiscal.impostos.codigoCfop === undefined || state.item.itemNotaFiscal.impostos.codigoCfop === 0) {
        apresentarMensagemAlerta('O CFOP deve ser informado!');
        return false;
      }

      if (!validarImpostos()) {
        return false;
      }

      if (!validarEstoque()) {
        return false;
      }

      if (!validarPreco()) {
        return false;
      }

      return true;
    }

    function atualizarDadosFornecedor() {
      if (state.item.itemNotaFiscal.codigoUnidade === undefined || state.item.itemNotaFiscal.codigoUnidade === 0) {
        state.item.itemNotaFiscal.codigoUnidade = state.item.codigoUnidadeNfFornecedor;
      }

      state.item.valorTotalNfFornecedor = servicoCalculosComerciais.calculaValorTotal(state.item.quantidadeNfFornecedor, state.item.valorUnitarioNfFornecedor, state.item.descontoUnitarioNfFornecedor, 0);
      state.item.itemNotaFiscal.quantidade = state.item.quantidadeNfFornecedor;
      state.item.itemNotaFiscal.precoUnitario = state.item.valorUnitarioNfFornecedor;
      state.item.itemNotaFiscal.valorDesconto = state.item.descontoUnitarioNfFornecedor;
      state.item.itemNotaFiscal.valorTotal = state.item.valorTotalNfFornecedor;
      calcularTotalNota();
    }

    async function atualizarCfopEntrada() {
      state.carregandoCfopEntrada = true;
      if (state.item.cfopNfFornecedor !== undefined && state.item.cfopNfFornecedor !== 0) {
        const cfop = await servicoCfop.obter(state.item.cfopNfFornecedor, props.empresa);
        state.item.itemNotaFiscal.impostos.codigoCfop = await servicoCfop.obterIdentificador(servicoCfop.inverterCfop(cfop.codigoCfop));
      }
      state.carregandoCfopEntrada = false;
    }

    function calculaTotalItemEAtualizaTotalNota() {
      state.item.itemNotaFiscal.valorTotal = servicoCalculosComerciais.calculaValorTotal(state.item.itemNotaFiscal.quantidade, state.item.itemNotaFiscal.precoUnitario, state.item.itemNotaFiscal.valorDesconto, 0);
      calcularTotalNota();
    }

    function atualizarQtdEstoque() {
      if (state.item.itemNotaFiscal.estoques.length === 1) {
        state.item.itemNotaFiscal.estoques[0].quantidade = state.item.itemNotaFiscal.quantidade;
      }
      calculaTotalItemEAtualizaTotalNota();
    }

    function limparEstoques() {
      state.item.itemNotaFiscal.estoques = [] as INotaFiscalItemEstoque[];
    }

    function salvar() {
      state.item.itemNotaFiscal.estoques.forEach((estoque, index) => {
        if (state.item.itemNotaFiscal.estoques[index].itemNovo) {
          state.item.itemNotaFiscal.estoques[index].codigoEstoqueItem = 0;
        }
      });

      if (validarCampos()) {
        if (state.item.codigo === undefined) {
          state.item.codigo = 0;
        } else {
          state.item.alterado = true;
        }
        emit('salvar', state.item);
      }
    }

    async function proximoItem() {
      emit('proximoItem');
    }

    async function itemAnterior() {
      emit('itemAnterior');
    }

    async function calcularTributacoes() {
      const retornoCalculoTributacoes = await servicoCalculosComerciais.calcularTributacoes(state.item.itemNotaFiscal, incidenciasCalculo);
      if (UtilitarioGeral.objetoValido(retornoCalculoTributacoes)) {
        state.item.itemNotaFiscal.impostos.icms.baseCalculoIcms = retornoCalculoTributacoes.icms.baseCalculoIcms;
        state.item.itemNotaFiscal.impostos.icms.valorIcms = retornoCalculoTributacoes.icms.valorIcms;

        state.item.itemNotaFiscal.impostos.st.baseCalculoSubstituicaoTributaria = retornoCalculoTributacoes.icms.baseCalculoSubstituicaoTributaria;
        state.item.itemNotaFiscal.impostos.st.valorSubstituicaoTributaria = retornoCalculoTributacoes.icms.valorSubstituicaoTributaria;

        state.item.itemNotaFiscal.impostos.ipi.baseCalculoIpi = retornoCalculoTributacoes.ipi.baseCalculo;
        state.item.itemNotaFiscal.impostos.ipi.valorIpi = retornoCalculoTributacoes.ipi.valor;

        state.item.itemNotaFiscal.impostos.pis.baseCalculoPis = retornoCalculoTributacoes.pis.baseCalculo;
        state.item.itemNotaFiscal.impostos.pis.valorPis = retornoCalculoTributacoes.pis.valor;

        state.item.itemNotaFiscal.impostos.cofins.baseCalculoCofins = retornoCalculoTributacoes.cofins.baseCalculo;
        state.item.itemNotaFiscal.impostos.cofins.valorCofins = retornoCalculoTributacoes.cofins.valor;
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparCampos();
      if (modalBase.computedVisivel) {
        state.item = UtilitarioGeral.clonarObjeto(props.item);
      }
      telaBase.carregando = false;
    });

    watch(async () => props.item, async () => {
      state.item = props.item;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      UtilitarioGeral,
      UtilitarioMascara,
      calcularCusto,
      atualizarDadosFornecedor,
      atualizarCfopEntrada,
      limparEstoques,
      salvar,
      proximoItem,
      itemAnterior,
      calcularTotalNota,
      atualizarQtdEstoque,
      calculaTotalItemEAtualizaTotalNota,
      calcularTributacoes,
      obterEstoquesDisponiveis,
    };
  },
});

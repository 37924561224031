
import {
  computed, defineComponent, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import { IRecebimentoMercadoriaItem, IRecebimentoMercadoriaItemCusto } from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import ServicoTipoCusto from '@/servicos/Cadastros/Precificacao/ServicoTipoCusto';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTORetornoCalculoCustoProduto } from '@/models/DTO/Cadastros/Precificacao/IDTORetornoCalculoCustoProduto';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export default defineComponent({
  name: 'RecebimentoMercadoriasItemCusto',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    item: {
      type: Object as () => IRecebimentoMercadoriaItem,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    tiposCustosCadastrados: {
      type: Array as () => ITipoCusto[],
      required: true,
    },
    retornosCalculoCusto: {
      type: Array as () => IDTORetornoCalculoCustoProduto[],
      default: [] as IDTORetornoCalculoCustoProduto[],
    },
  },
  components: {
    Card,
    Icone,
    CampoNumerico,
  },
  emits: ['update:atualizarCusto', 'update:item', 'calcularCusto'],
  setup(props, { emit }) {
    const servicoTipoCusto = new ServicoTipoCusto();
    servicoTipoCusto.requisicaoSistema();

    const computedItem = computed({
      get: () => props.item,
      set: (valor: IRecebimentoMercadoriaItem) => {
        emit('update:item', valor);
      },
    });

    const state = reactive({
      preparandoCustos: false,
    });

    function obtemDescricaoTipoCusto(coditoTipoCusto:number):string {
      if (UtilitarioGeral.validaLista(props.tiposCustosCadastrados)) {
        const tipoCusto = props.tiposCustosCadastrados.find((c) => c.codigo === coditoTipoCusto);
        if (tipoCusto !== undefined) {
          return UtilitarioGeral.primeiraLetraMaiuscula(tipoCusto.descricao);
        }
      }

      return '';
    }

    function obtemCasasDecimaisTipoCusto(coditoTipoCusto:number):number {
      if (UtilitarioGeral.validaLista(props.tiposCustosCadastrados)) {
        const tipoCusto = props.tiposCustosCadastrados.find((c) => c.codigo === coditoTipoCusto);
        if (tipoCusto !== undefined) {
          return tipoCusto.casasDecimais;
        }
      }

      return 2;
    }

    function obtemIndexTipoCusto(coditoTipoCusto:number):number {
      if (UtilitarioGeral.validaLista(computedItem.value.custos)) {
        const index = computedItem.value.custos.findIndex((c) => c.codigoTipoCusto === coditoTipoCusto);
        if (index >= 0) { return index; }
      }

      return 0;
    }

    function obterApresentacaoVariavel(variavel: string, valorVariavel: number) {
      switch (variavel) {
        case 'QuantidadeProduto':
          return `Quantidade: ${valorVariavel}`;
        case 'QuantidadeEstoqueAtual':
          return `Estoque Atual: ${valorVariavel}`;
        case 'ValorTotalProduto':
          return `Total Produto: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorCustoAtual':
          return `Custo Atual: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorIcms':
          return `ICMS: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorSubstituicaoTributaria':
          return `ST: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorDifal':
          return `DIFAL: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorIpi':
          return `IPI: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorPis':
          return `PIS: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorCofins':
          return `COFINS: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorIof':
          return `IOF: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorImpostoImportacao':
          return `Imposto de Importação: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorFrete':
          return `Frete: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorSeguro':
          return `Seguro: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorOutrasDespesas':
          return `Outras Despesas: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorIcmsFreteConhecimento':
          return `Icms Frete Conhecimento: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        case 'ValorDespesasAduaneiras':
          return `Despesas Aduaneiras: ${UtilitarioMascara.mascararValor(valorVariavel, 2)}`;
        default:
          return '';
      }
    }

    function obterValorVariavel(variavel: string) {
      switch (variavel) {
        case 'QuantidadeProduto':
          return props.item.itemNotaFiscal.quantidade;
        case 'QuantidadeEstoqueAtual':
          return 10;
        case 'ValorTotalProduto':
          return props.item.itemNotaFiscal.valorTotal;
        case 'ValorCustoAtual':
          return 1;
        case 'ValorIcms':
          return props.item.itemNotaFiscal.impostos.icms.valorIcms;
        case 'ValorSubstituicaoTributaria':
          return props.item.itemNotaFiscal.impostos.st.valorSubstituicaoTributaria;
        case 'ValorDifal':
          return 0;
        case 'ValorIpi':
          return props.item.itemNotaFiscal.impostos.ipi.valorIpi;
        case 'ValorPis':
          return props.item.itemNotaFiscal.impostos.pis.valorPis;
        case 'ValorCofins':
          return props.item.itemNotaFiscal.impostos.cofins.valorCofins;
        case 'ValorIof':
          if (props.item.itemNotaFiscal.impostos.importacao !== undefined) {
            return props.item.itemNotaFiscal.impostos.importacao.valorIof;
          }
          return 0;
        case 'ValorImpostoImportacao':
          if (props.item.itemNotaFiscal.impostos.importacao !== undefined) {
            return props.item.itemNotaFiscal.impostos.importacao.valorImportacao;
          }
          return 0;
        case 'ValorFrete':
          return props.item.itemNotaFiscal.valorFrete;
        case 'ValorSeguro':
          return props.item.itemNotaFiscal.valorSeguro;
        case 'ValorOutrasDespesas':
          return props.item.itemNotaFiscal.outrasDespesas;
        case 'ValorIcmsFreteConhecimento':
          return 0;
        case 'ValorDespesasAduaneiras':
          if (props.item.itemNotaFiscal.impostos.importacao !== undefined) {
            return props.item.itemNotaFiscal.impostos.importacao.despesasAduaneiras;
          }
          return 0;
        default:
          return 0;
      }
    }

    function obtemDetalheCalculoCusto(custoItem:IRecebimentoMercadoriaItemCusto) {
      const variaveis = [] as string[];
      variaveis.push('QuantidadeProduto');
      variaveis.push('QuantidadeEstoqueAtual');
      variaveis.push('ValorTotalProduto');
      variaveis.push('ValorCustoAtual');
      variaveis.push('ValorIcmsFreteConhecimento');
      variaveis.push('ValorIcms');
      variaveis.push('ValorSubstituicaoTributaria');
      variaveis.push('ValorDifal');
      variaveis.push('ValorIpi');
      variaveis.push('ValorPis');
      variaveis.push('ValorCofins');
      variaveis.push('ValorIof');
      variaveis.push('ValorImpostoImportacao');
      variaveis.push('ValorFrete');
      variaveis.push('ValorSeguro');
      variaveis.push('ValorOutrasDespesas');
      variaveis.push('ValorDespesasAduaneiras');

      let formulaComValores = custoItem.formula;
      let formulaComDetalhes = custoItem.formula;
      variaveis.forEach((v) => {
        const valorVariavel = obterValorVariavel(v);
        formulaComValores = formulaComValores.replace(v, valorVariavel.toString().replace(',', '.'));
        const detalhesVariavel = obterApresentacaoVariavel(v, valorVariavel);
        formulaComDetalhes = formulaComDetalhes.replace(v, detalhesVariavel);
      });
      return formulaComDetalhes;
    }

    function apresentarDetalheCalculoCusto(coditoTipoCusto:number):boolean {
      if (UtilitarioGeral.validaLista(props.retornosCalculoCusto)) {
        const calculoCusto = props.retornosCalculoCusto.find((c) => c.codigoTipoCusto === coditoTipoCusto);
        if (calculoCusto !== undefined) {
          return true;
        }
      }

      return false;
    }

    function calcularCusto() {
      emit('calcularCusto');
    }

    return {
      props,
      state,
      computedItem,
      calcularCusto,
      obtemIndexTipoCusto,
      obtemDescricaoTipoCusto,
      obtemCasasDecimaisTipoCusto,
      obtemDetalheCalculoCusto,
      apresentarDetalheCalculoCusto,
      UtilitarioGeral,
    };
  },
});

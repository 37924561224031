import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ed7d1bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_2 = { class: "ant-input-group-addon" }
const _hoisted_3 = {
  role: "img",
  "aria-label": "pesquisar",
  type: "pesquisar",
  class: "anticon"
}
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = {
  key: 0,
  class: "ant-input-group-addon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_icone, {
            style: {"font-size":"18px"},
            nome: "pesquisar"
          })
        ])
      ]),
      _withDirectives(_createElementVNode("input", {
        placeholder: _ctx.props.placeholder,
        type: "text",
        class: "ant-input",
        style: {"text-align":"left !important"},
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comptedBuscaRapida) = $event)),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.buscarDados()))
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.comptedBuscaRapida]
      ]),
      (_ctx.comptedBuscaRapida !== '')
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createVNode(_component_a_tooltip, {
              title: "Limpar busca rápida",
              placement: "left"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.limparBuscaRapida()))
                }, [
                  _createVNode(_component_icone, {
                    style: {"font-size":"18px","margin-right":"13px"},
                    nome: "clear"
                  })
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
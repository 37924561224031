
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { INotaFiscalDetalhePagamento, INotaFiscalDetalhePagamentoAnexo, INotaFiscalDetalhePagamentoCentroCusto } from '@/models/Entidades/Fiscal/INotaFiscal';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import NotaFiscalDetalhePagamentoModal from '@/components/Fiscal/NotasFiscais/NotaFiscalDetalhePagamentoModal.vue';
import Icone from '@/core/components/Icone.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';

export default defineComponent({
  name: 'DevolucaoComprasPagamentoTitulosReceber',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    totalDetalhesPagamento: {
      type: Number,
      required: true,
    },
    detalhesPagamento: {
      type: Array as () => INotaFiscalDetalhePagamento[],
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    MensagemSemDados,
    CampoNumerico,
    NotaFiscalDetalhePagamentoModal,
    Icone,
  },
  emits: ['update:detalhesPagamento', 'atualizarDetalhePagamento', 'excluirDetalhePagamento'],
  setup(props, { emit }) {
    const state = reactive({
      colunasGrade: [] as IColumn[],
      detalheSelecionado: {} as INotaFiscalDetalhePagamento,
      indexSelecionado: -1,
      exibirLancamento: false,
      telaMobile: false,
    });

    const computedDetalhesPagamento = computed({
      get: () => props.detalhesPagamento,
      set: (val: INotaFiscalDetalhePagamento[]) => {
        emit('update:detalhesPagamento', val);
      },
    });

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Detalhe', dataIndex: 'detalhe', key: 'DetalhePagamento', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasGrade = [
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 0, visible: true, width: 5, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'T.Documento', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 1, visible: true, width: 10, ordering: false, align: 'left', ellipsis: true,
          },
          {
            title: 'Vencimento', dataIndex: 'vencimento', key: 'Vencimento', position: 3, visible: true, ordering: false, align: 'center', width: 4, ellipsis: true,
          },
          {
            title: 'Valor', dataIndex: 'valorTotal', key: 'ValorTotal', position: 4, visible: true, ordering: false, align: 'right', width: 5, ellipsis: true,
          },
          {
            title: 'Conta', dataIndex: 'conta', key: 'Conta', position: 5, visible: true, align: 'left', width: 10, ellipsis: true,
          },
          {
            title: 'Categoria', dataIndex: 'categoria', key: 'Categoria', position: 6, visible: true, ordering: false, align: 'left', width: 10, ellipsis: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 9, visible: true, fixed: 'right', width: 3, align: 'center', ellipsis: true,
          },
        ];
      }
    }

    function adicionarDetalhe() {
      state.indexSelecionado = -1;
      state.detalheSelecionado = {} as INotaFiscalDetalhePagamento;
      state.detalheSelecionado.centrosCustos = [] as INotaFiscalDetalhePagamentoCentroCusto[];
      state.detalheSelecionado.anexos = [] as INotaFiscalDetalhePagamentoAnexo[];
      state.exibirLancamento = true;
    }

    function editarDetalhe(index: number) {
      state.indexSelecionado = index;
      state.detalheSelecionado = computedDetalhesPagamento.value[index];
      state.exibirLancamento = true;
    }

    function salvarDetalhe(detalhes: INotaFiscalDetalhePagamento[]) {
      if (state.indexSelecionado === -1) {
        computedDetalhesPagamento.value.push(detalhes[0]);
        state.indexSelecionado = computedDetalhesPagamento.value.length - 1;
      } else {
        computedDetalhesPagamento.value[state.indexSelecionado] = detalhes[0];
      }
      emit('atualizarDetalhePagamento', state.indexSelecionado);
    }

    function removerDetalhe(index: number) {
      const codigoDetalhe = computedDetalhesPagamento.value[index].codigo;
      computedDetalhesPagamento.value.splice(index, 1);
      emit('excluirDetalhePagamento', codigoDetalhe);
    }

    async function confirmarExclusao(index: number) {
      Modal.confirm({
        title: 'Você confirma a exclusão do detalhe',
        content: `${computedDetalhesPagamento.value[index].numeroTitulo} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await removerDetalhe(index); },
      });
    }

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      preencherColunas();
    });

    return {
      props,
      state,
      UtilitarioMascara,
      UtilitarioData,
      computedDetalhesPagamento,
      adicionarDetalhe,
      editarDetalhe,
      salvarDetalhe,
      confirmarExclusao,
    };
  },
});

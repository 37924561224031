
import {
  defineComponent, computed, reactive, onBeforeMount,
} from 'vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import Icone from '@/core/components/Icone.vue';
import { IDTORecebimentoMercadorias } from '@/models/DTO/Compras/IDTORecebimentoMercadorias';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';

export default defineComponent({
  name: 'RecebimentoMercadoriasListagem',
  components: {
    Icone,
    MensagemSemDados,
  },
  props: {
    visualizacaoKanban: {
      type: Boolean,
      default: true,
    },
    recebimentos: {
      type: Array as () => IDTORecebimentoMercadorias[],
      required: true,
    },
    selecionados: {
      type: Array as () => number[],
      required: true,
    },
    paginacao: {
      type: Object as () => IPagination,
      required: true,
    },
  },
  emits: ['update:recebimentos', 'update:visualizacaoKanban', 'update:paginacao', 'update:selecionados', 'selecaoAlterada', 'buscarRecebimentos', 'visualizar', 'imprimir', 'novoLancamento'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { gradeBase, preencheOrdenacaoSelecionada, ordenacaoAtiva } = useGradeBase();

    const state = reactive({
      colunas: [] as IColumn[],
      paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      totalRegistrosComMascara: '100',
      telaMobile: false,
    });

    const computedRecebimentos = computed({
      get: () => props.recebimentos,
      set: (valor: IDTORecebimentoMercadorias[]) => {
        emit('update:recebimentos', valor);
      },
    });

    const computedVisualizacaoKanban = computed({
      get: () => props.visualizacaoKanban,
      set: (valor: boolean) => {
        emit('update:visualizacaoKanban', valor);
      },
    });

    const computedSelecionados = computed({
      get: () => props.selecionados,
      set: (valor: number[]) => {
        emit('update:selecionados', valor);
      },
    });

    const computedPaginacao = computed({
      get: () => props.paginacao,
      set: (valor: IPagination) => {
        emit('update:paginacao', valor);
      },
    });

    function obterClasseStatus(status: string) {
      switch (status) {
        case 'Em digitação':
          return 'em-digitacao';
        case 'Aguardando conferência':
          return 'aguardando-conferencia';
        case 'Conferência em andamento':
          return 'conferencia-andamento';
        case 'Conferido':
          return 'conferido';
        case 'Concluído':
          return 'concluido';
        case 'Devolvido':
          return 'devolvido';
        default:
          return 'cancelado';
      }
    }

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Recebimento', dataIndex: 'recebimento', key: 'RecebimentoMercadorias', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunas = [
          {
            title: 'PK', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: true, customRenderRow: ECustomRenderRow.LinkAcao,
          },
          {
            title: 'Recebimento', dataIndex: 'numero', key: 'Numero', position: 1, visible: true, ordering: true, customRenderRow: ECustomRenderRow.LinkAcao, width: 110,
          },
          {
            title: 'Nº da Nota', dataIndex: 'numeroNotaFiscal', key: 'NumeroNotaFiscal', position: 3, visible: true, ordering: true, width: 100,
          },
          {
            title: 'Status', dataIndex: 'descricaoStatus', key: 'Status', position: 2, visible: true, ordering: true, width: 250, align: 'center',
          },
          {
            title: 'Modelo', dataIndex: 'modelo', key: 'Modelo', position: 4, visible: true, ordering: true, width: 75, align: 'center',
          },
          {
            title: 'Dt de Emissão', dataIndex: 'dataEmissao', key: 'DataEmissao', position: 5, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararData, width: 120, align: 'center',
          },
          {
            title: 'Emitente', dataIndex: 'nomeRazaoSocialEmitente', key: 'NomeRazaoSocialEmitente', ellipsis: true, position: 6, visible: true, ordering: true,
          },
          {
            title: 'Valor Total', dataIndex: 'totalNotaFiscal', key: 'totalNotaFiscal', position: 7, visible: true, fixed: 'right', ordering: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 100,
          },
          {
            title: 'Ações', key: 'acoes', position: 8, visible: true, width: 100, align: 'center', fixed: 'right', customRenderRow: ECustomRenderRow.IconeAcoes,
          },
        ];
      }
    }

    async function ordenarDados(key: string, sort: string) {
      await preencheOrdenacaoSelecionada(key, sort);
      emit('buscarRecebimentos', gradeBase.ordenacaoSelecionada);
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(state.timerRegistros);
      let totalRegistros = 100;
      state.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        computedPaginacao.value.total = totalRegistros;
        state.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        emit('buscarRecebimentos');
      }, 600);
    }

    async function mudarPagina(current: number, pageSize: number) {
      computedPaginacao.value.current = current;
      computedPaginacao.value.pageSize = pageSize;
      emit('buscarRecebimentos');
    }

    async function mostrarNovaPagina(current: number, pageSize: number) {
      computedPaginacao.value.current = 1;
      computedPaginacao.value.pageSize = pageSize;
      emit('buscarRecebimentos');
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      computedSelecionados.value = selectedRowKeys;
      emit('selecaoAlterada');
    };

    function visualizar(index: number) {
      emit('visualizar', props.recebimentos[index].codigo);
    }

    function imprimir(index: number) {
      emit('imprimir', props.recebimentos[index].codigo);
    }

    function novoLancamento() {
      emit('novoLancamento');
    }

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      preencherColunas();
      computedPaginacao.value.total = 100;
      computedPaginacao.value.showSizeChanger = true;
      computedPaginacao.value.pageSize = 100;
      computedPaginacao.value.pageSizeOptions = ['10', '50', '100', '500', '1000'];
    });

    return {
      state,
      props,
      window,
      telaBase,
      gradeBase,
      computedRecebimentos,
      computedVisualizacaoKanban,
      computedSelecionados,
      computedPaginacao,
      UtilitarioMascara,
      UtilitarioData,
      mudarPagina,
      ordenarDados,
      mostrarNovaPagina,
      alterarQuantidadeRegistroTotal,
      obterClasseStatus,
      mudarSelecao,
      ordenacaoAtiva,
      ECustomRenderRow,
      visualizar,
      imprimir,
      novoLancamento,
    };
  },
});


import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_tabela_preco = _resolveComponent("selecionar-tabela-preco")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_selecionar_sim_nao = _resolveComponent("selecionar-sim-nao")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.itens, (item, indexItem) => {
              return (_openBlock(), _createBlock(_component_card, {
                titulo: 'ITEM: ' + item.itemNotaFiscal.descricao,
                key: indexItem
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_table, {
                    columns: _ctx.state.colunasGrade.filter(item => item.visible),
                    "data-source": item.precos,
                    rowKey: "codigo",
                    pagination: false,
                    scroll: { y: 200},
                    size: "small",
                    rowClassName: "ss-table-campos"
                  }, {
                    bodyCell: _withCtx(({ column, record, index }) => [
                      (column.key === 'TabelaPreco')
                        ? (_openBlock(), _createBlock(_component_selecionar_tabela_preco, {
                            key: 0,
                            classCss: "ss-campo-selecao-table ss-recebimento-mercadoria-itens-atualizacao-preco",
                            title: column.title,
                            codigoSelecionado: record.itemTabelaPreco.codigoTabelaPreco,
                            disabled: true,
                            empresas: [_ctx.props.empresa]
                          }, null, 8, ["title", "codigoSelecionado", "empresas"]))
                        : (column.key === 'TipoCusto')
                          ? (_openBlock(), _createElementBlock("input", {
                              key: 1,
                              type: "text",
                              class: "ant-input",
                              value: _ctx.obtemDescricaoTipoCusto(record.itemTabelaPreco.codigoTipoCusto),
                              disabled: ""
                            }, null, 8, _hoisted_3))
                          : (column.key === 'ValorCusto')
                            ? (_openBlock(), _createBlock(_component_campo_numerico, {
                                key: 2,
                                valor: _ctx.obtemPrecoCustoItem(indexItem,record.itemTabelaPreco.codigoTipoCusto),
                                dataAttributeColuna: column.key,
                                monetario: true,
                                disabled: true,
                                classCss: "ss-campo-numero-table ss-recebimento-mercadoria-itens-atualizacao-preco",
                                classGroupCss: "ss-campo-numero-table",
                                onKeydown: [
                                  _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["tab"])),
                                  _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["enter"])),
                                  _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.elementoAnterior($event)), ["prevent"]), ["left"])),
                                  _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent"]), ["right"])),
                                  _withKeys(_withModifiers(($event: any) => (_ctx.elementoAbaixo($event,column.key)), ["prevent"]), ["down"]),
                                  _withKeys(_withModifiers(($event: any) => (_ctx.elementoAcima($event,column.key)), ["prevent"]), ["up"])
                                ]
                              }, null, 8, ["valor", "dataAttributeColuna", "onKeydown"]))
                            : (column.key === 'Markup')
                              ? (_openBlock(), _createBlock(_component_campo_numerico, {
                                  key: 3,
                                  dataAttributeColuna: column.key,
                                  valor: record.markup,
                                  "onUpdate:valor": ($event: any) => ((record.markup) = $event),
                                  percentual: true,
                                  disabled: !_ctx.props.editavel,
                                  classCss: "ss-campo-numero-table ss-recebimento-mercadoria-itens-atualizacao-preco",
                                  classGroupCss: "ss-campo-numero-table",
                                  onValorDigitado: ($event: any) => (_ctx.calcularPrecoVenda(indexItem,index,record.itemTabelaPreco.codigoTipoCusto,record.markup)),
                                  onKeydown: [
                                    _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["tab"])),
                                    _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["enter"])),
                                    _cache[6] || (_cache[6] = _withKeys(_withModifiers(($event: any) => (_ctx.elementoAnterior($event)), ["prevent"]), ["left"])),
                                    _cache[7] || (_cache[7] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent"]), ["right"])),
                                    _withKeys(_withModifiers(($event: any) => (_ctx.elementoAbaixo($event,column.key)), ["prevent"]), ["down"]),
                                    _withKeys(_withModifiers(($event: any) => (_ctx.elementoAcima($event,column.key)), ["prevent"]), ["up"])
                                  ]
                                }, null, 8, ["dataAttributeColuna", "valor", "onUpdate:valor", "disabled", "onValorDigitado", "onKeydown"]))
                              : (column.key === 'TipoPreco')
                                ? (_openBlock(), _createElementBlock("input", {
                                    key: 4,
                                    type: "text",
                                    class: "ant-input",
                                    value: _ctx.obtemDescricaoTipoPreco(record.itemTabelaPreco.codigoTipoPreco)
                                  }, null, 8, _hoisted_4))
                                : (column.key === 'ValorPreco')
                                  ? (_openBlock(), _createBlock(_component_campo_numerico, {
                                      key: 5,
                                      dataAttributeColuna: column.key,
                                      valor: record.precoVenda,
                                      "onUpdate:valor": ($event: any) => ((record.precoVenda) = $event),
                                      monetario: true,
                                      disabled: !_ctx.props.editavel,
                                      classCss: "ss-campo-numero-table ss-recebimento-mercadoria-itens-atualizacao-preco",
                                      classGroupCss: "ss-campo-numero-table",
                                      onKeydown: [
                                        _cache[8] || (_cache[8] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["tab"])),
                                        _cache[9] || (_cache[9] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent","exact"]), ["enter"])),
                                        _cache[10] || (_cache[10] = _withKeys(_withModifiers(($event: any) => (_ctx.elementoAnterior($event)), ["prevent"]), ["left"])),
                                        _cache[11] || (_cache[11] = _withKeys(_withModifiers(($event: any) => (_ctx.proximoElemento($event)), ["prevent"]), ["right"])),
                                        _withKeys(_withModifiers(($event: any) => (_ctx.elementoAbaixo($event,column.key)), ["prevent"]), ["down"]),
                                        _withKeys(_withModifiers(($event: any) => (_ctx.elementoAcima($event,column.key)), ["prevent"]), ["up"])
                                      ]
                                    }, null, 8, ["dataAttributeColuna", "valor", "onUpdate:valor", "disabled", "onKeydown"]))
                                  : (column.key === 'Atualizar')
                                    ? (_openBlock(), _createBlock(_component_selecionar_sim_nao, {
                                        key: 6,
                                        valor: record.atualizarPreco,
                                        "onUpdate:valor": ($event: any) => ((record.atualizarPreco) = $event),
                                        classCss: "ss-campo-selecao-table ss-recebimento-mercadoria-itens-atualizacao-preco",
                                        dataAttributeColuna: column.key
                                      }, null, 8, ["valor", "onUpdate:valor", "dataAttributeColuna"]))
                                    : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["columns", "data-source"])
                ]),
                _: 2
              }, 1032, ["titulo"]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    })
  ]))
}

import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarTabelaPreco from '@/components/Cadastros/Precificacao/TabelasPreco/SelecionarTabelaPreco.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { IRecebimentoMercadoriaItem } from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import { ITabelaPrecoItemProduto } from '@/models/Entidades/Cadastros/Precificacao/ITabelaPreco';
import ServicoTipoPreco from '@/servicos/Cadastros/Precificacao/ServicoTipoPreco';
import { ITipoPreco } from '@/models/Entidades/Cadastros/Precificacao/ITipoPreco';
import { useFocusBase } from '@/core/composables/FocusBase';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';

export default defineComponent({
  name: 'RecebimentoMercadoriasAtualizacaoPrecos',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    itens: {
      type: Array as () => IRecebimentoMercadoriaItem[],
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    tiposCustosCadastrados: {
      type: Array as () => ITipoCusto[],
      required: true,
    },
  },
  components: {
    Card,
    CampoNumerico,
    SelecionarSimNao,
    SelecionarTabelaPreco,
  },
  emits: ['update:itens'],
  setup(props, { emit }) {
    const servicoTipoPreco = new ServicoTipoPreco();
    servicoTipoPreco.requisicaoSistema();
    const servicoCalculosComerciais = new ServicoCalculosComerciais();
    const {
      focusBase, focusElemento, proximoElemento, elementoAnterior, elementoAcima, elementoAbaixo,
    } = useFocusBase();
    focusBase.classElementos = 'ss-recebimento-mercadoria-itens-atualizacao-preco';

    const state = reactive({
      colunasGrade: [] as IColumn[],
      tiposCustosCadastrados: [] as ITipoCusto[],
      tiposPrecosCadastrados: [] as ITipoPreco[],
      telaMobile: false,
      itensTabelaPreco: [] as ITabelaPrecoItemProduto [],
    });

    state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();

    const computedItens = computed({
      get: () => props.itens,
      set: (val: IRecebimentoMercadoriaItem[]) => {
        emit('update:itens', val);
      },
    });

    if (state.telaMobile) {
      state.colunasGrade = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
        },
        {
          title: 'Item', dataIndex: 'item', key: 'Item', position: 2, visible: true, ellipsis: true,
        },
      ];
    } else {
      state.colunasGrade = [
        {
          title: 'Tabela Preço', dataIndex: 'tabelaPreco', key: 'TabelaPreco', position: 2, visible: true, fixed: 'left', width: 200, ordering: true, align: 'left', ellipsis: true,
        },
        {
          title: 'Tipo Custo', dataIndex: 'tipoCusto', key: 'TipoCusto', position: 3, visible: true, ordering: false, align: 'left', width: 100, ellipsis: true,
        },
        {
          title: 'Valor', dataIndex: 'valorCusto', key: 'ValorCusto', position: 4, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true,
        },
        {
          title: '% Markup', dataIndex: 'markup', key: 'Markup', position: 5, visible: true, align: 'right', width: 50, ellipsis: true,
        },
        {
          title: 'Tipo Preço', dataIndex: 'tipoPreco', key: 'TipoPreco', position: 6, visible: true, ordering: false, align: 'left', width: 100, ellipsis: true,
        },
        {
          title: 'Valor', dataIndex: 'valorPreco', key: 'ValorPreco', position: 7, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true,
        },
        {
          title: 'Atualizar', dataIndex: 'atualizar', key: 'Atualizar', position: 8, visible: true, ordering: false, align: 'center', width: 40, ellipsis: true,
        },
      ];
    }

    onBeforeMount(async () => {
      state.tiposPrecosCadastrados = await servicoTipoPreco.obterTodosTiposPrecos();
    });

    function obtemDescricaoTipoCusto(codigoTipoCusto: number):string {
      const tipoCusto = props.tiposCustosCadastrados.find((c) => c.codigo === codigoTipoCusto);
      if (tipoCusto !== undefined) {
        return tipoCusto.descricao;
      }
      return '';
    }

    function obtemPrecoCustoItem(indexItemRecebimento: number, codigoTipoCusto: number):number {
      const custoItemRecebimento = props.itens[indexItemRecebimento].custos.find((c) => c.codigoTipoCusto === codigoTipoCusto);
      if (custoItemRecebimento !== undefined) {
        return custoItemRecebimento.valorCusto;
      }
      return 0;
    }
    function obtemDescricaoTipoPreco(codigoTipoPreco: number):string {
      const tipoPreco = state.tiposPrecosCadastrados.find((c) => c.codigo === codigoTipoPreco);
      if (tipoPreco !== undefined) {
        return tipoPreco.descricao;
      }
      return '';
    }

    function calcularPrecoVenda(indexItemRecebimento: number, indexPrecoVenda: number, codigoTipoCusto: number, markup: number) {
      const custoItemRecebimento = obtemPrecoCustoItem(indexItemRecebimento, codigoTipoCusto);
      const precoVenda = servicoCalculosComerciais.calculaPrecoVenda(custoItemRecebimento, markup);
      computedItens.value[indexItemRecebimento].precos[indexPrecoVenda].precoVenda = precoVenda;
    }
    return {
      props,
      state,
      UtilitarioMascara,
      computedItens,
      focusElemento,
      proximoElemento,
      elementoAnterior,
      elementoAbaixo,
      elementoAcima,
      obtemPrecoCustoItem,
      obtemDescricaoTipoCusto,
      obtemDescricaoTipoPreco,
      calcularPrecoVenda,
    };
  },
});

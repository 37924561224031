
import {
  defineComponent, reactive, onBeforeMount, computed,
} from 'vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import BuscaRapida from '@/core/components/BuscaRapida.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import { IDTORecebimentoMercadoriasBuscaFinanceiro } from '@/models/DTO/Compras/IDTORecebimentoMercadoriasBuscaFinanceiro';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { INotaFiscalDetalhePagamento } from '@/models/Entidades/Fiscal/INotaFiscal';
import ServicoRecebimentoMercadoria from '@/servicos/Compras/ServicoRecebimentoMercadoria';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'RecebimentoMercadoriasBuscaFinanceiro',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    codigoPessoa: {
      type: Number,
      default: 0,
    },
    totalNotaFiscal: {
      type: Number,
      default: 0,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    detalhesPagamento: {
      type: Array as () => INotaFiscalDetalhePagamento[],
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    BuscaRapida,
    MensagemSemDados,
    SelecionarTipoDocumentoFinanceiro,
    Totalizador,
  },
  emits: ['update:detalhesPagamento'],
  setup(props, { emit }) {
    const servicoRecebimentoMercadoria = new ServicoRecebimentoMercadoria();

    const state = reactive({
      idselecionados: [] as number[],
      buscaFinanceiro: {} as IDTORecebimentoMercadoriasBuscaFinanceiro,
      buscandoTitulos: false,
      titulosFinanceiros: [] as IDTOTituloFinanceiro[],
      telaMobile: false,
      colunasGrade: [] as IColumn[],
      quantidadeTitulosSelecionados: 0,
      totalTitulosSelecionados: 0,
      activeKey: '1',
    });
    state.idselecionados = [];
    const computedDetalhesPagamento = computed({
      get: () => props.detalhesPagamento,
      set: (val: INotaFiscalDetalhePagamento[]) => {
        emit('update:detalhesPagamento', val);
      },
    });

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'detalhe', key: 'TituloFinanceiro', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasGrade = [
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 0, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'T.Documento', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 1, visible: true, width: 120, ordering: false, align: 'left', ellipsis: true,
          },
          {
            title: 'Vencimento', dataIndex: 'vencimento', key: 'Vencimento', position: 3, visible: true, ordering: false, align: 'center', width: 100, ellipsis: true,
          },
          {
            title: 'Valor', dataIndex: 'valorTotal', key: 'ValorTotal', position: 4, visible: true, ordering: false, align: 'right', width: 100, ellipsis: true,
          },
          {
            title: 'Status', dataIndex: 'status', key: 'Status', position: 5, visible: true, width: 100, ordering: false, align: 'left', ellipsis: true,
          },
          {
            title: 'Conta', dataIndex: 'conta', key: 'Conta', position: 6, visible: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Categoria', dataIndex: 'categoria', key: 'Categoria', position: 7, visible: true, ordering: false, align: 'left', width: 250, ellipsis: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 8, visible: true, width: 70, align: 'center', ellipsis: true,
          },
        ];
      }
    }

    async function buscarTitulos() {
      state.buscandoTitulos = true;
      state.quantidadeTitulosSelecionados = 0;
      state.totalTitulosSelecionados = 0;
      state.idselecionados = [];
      state.buscaFinanceiro.omitirTitulos = [];
      state.buscaFinanceiro.codigoEmpresa = props.empresa;
      state.buscaFinanceiro.codigoPessoa = props.codigoPessoa;
      state.buscaFinanceiro.totalNotaFiscal = props.totalNotaFiscal;
      if (UtilitarioGeral.validaLista(computedDetalhesPagamento.value)) {
        const titulosSelecionados = computedDetalhesPagamento.value.filter((c) => c.codigoTituloFinanceiro > 0);
        if (UtilitarioGeral.validaLista(titulosSelecionados)) {
          state.buscaFinanceiro.omitirTitulos = titulosSelecionados.map((c) => c.codigoTituloFinanceiro);
        }
      }
      state.titulosFinanceiros = await servicoRecebimentoMercadoria.obterTitulosFinanceiros(state.buscaFinanceiro);
      state.buscandoTitulos = false;
    }

    function atualizarTotalizadores() {
      state.quantidadeTitulosSelecionados = 0;
      state.totalTitulosSelecionados = 0;

      state.titulosFinanceiros.forEach((tituloFinanceiro) => {
        const index = state.idselecionados.findIndex((c) => c === tituloFinanceiro.codigo);
        if (index >= 0) {
          state.totalTitulosSelecionados += tituloFinanceiro.valorTotal;
          state.quantidadeTitulosSelecionados += 1;
        }
      });
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      state.idselecionados = selectedRowKeys;

      atualizarTotalizadores();
    };

    function utilizarTituloFinanceiro(titulo:IDTOTituloFinanceiro) {
      const detalhePagamento:INotaFiscalDetalhePagamento = {} as INotaFiscalDetalhePagamento;
      detalhePagamento.codigo = 0;
      detalhePagamento.codigoNotaFiscal = 0;
      detalhePagamento.codigoTipoDocumentoFinanceiro = titulo.codigoTipoDocumentoFinanceiro;
      detalhePagamento.descricaoTipoDocumentoFinanceiro = titulo.descricaoTipoDocumentoFinanceiro;
      detalhePagamento.codigoConta = titulo.codigoConta;
      detalhePagamento.descricaoConta = titulo.descricaoConta;
      detalhePagamento.codigoPlanoContaCategoria = titulo.codigoPlanoContaCategoria;
      detalhePagamento.nomePlanoContaCategoria = titulo.nomePlanoContaCategoria;
      detalhePagamento.codigoTituloFinanceiro = titulo.codigo;
      detalhePagamento.statusTituloFinanceiro = titulo.status;
      detalhePagamento.numeroTitulo = titulo.numeroTitulo;
      detalhePagamento.parcela = titulo.parcela;
      detalhePagamento.data = titulo.dataVencimento;
      detalhePagamento.valorPagamento = titulo.valorTotal;
      detalhePagamento.acrescimos = 0;
      detalhePagamento.outrasTaxas = 0;
      detalhePagamento.valorTotal = titulo.valorTotal;
      detalhePagamento.observacoes = '';
      detalhePagamento.gerarCarteira = true;
      detalhePagamento.gerarCaixa = false;
      detalhePagamento.alterado = false;
      detalhePagamento.anexos = [];
      detalhePagamento.centrosCustos = [];
      computedDetalhesPagamento.value.push(detalhePagamento);
      const index = state.titulosFinanceiros.findIndex((c) => c.codigo === titulo.codigo);
      if (index >= 0) {
        state.titulosFinanceiros.splice(index, 1);
      }
    }

    function adicionarTodosSelecionados() {
      state.idselecionados.forEach((codigoTitulo) => {
        const titulo = state.titulosFinanceiros.find((c) => c.codigo === codigoTitulo);
        if (titulo !== undefined) {
          utilizarTituloFinanceiro(titulo);
        }
      });

      state.idselecionados = [];
    }
    onBeforeMount(async () => {
      preencherColunas();
      await buscarTitulos();
    });
    return {
      props,
      state,
      UtilitarioMascara,
      UtilitarioData,
      buscarTitulos,
      computedDetalhesPagamento,
      mudarSelecao,
      utilizarTituloFinanceiro,
      adicionarTodosSelecionados,
    };
  },
});


import { defineComponent, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { IRecebimentoMercadoria } from '@/models/Entidades/Compras/IRecebimentoMercadoria';

export default defineComponent({
  name: 'RecebimentoMercadoriasImportarChaveAcesso',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
  },
  components: {
  },
  emits: ['importarNfe'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();
    const state = reactive({
      consultandoNFe: false,
      chaveAcesso: '',
      recebimentoMercadoria: {} as IRecebimentoMercadoria,
    });

    const servicoNotaFiscal = new ServicoNotaFiscal();

    function validarConsultaNFe(): boolean {
      if (!UtilitarioGeral.objetoValido(state.recebimentoMercadoria)) {
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.recebimentoMercadoria.notaFiscal.chaveAcesso)) {
        return false;
      }

      return true;
    }

    function delay(ms:number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async function consultarNotaFiscal() {
      if (!UtilitarioGeral.valorValido(state.chaveAcesso)) {
        apresentarMensagemAlerta('Digite a Chave de acesso!');
        return;
      }

      if (state.chaveAcesso.length !== 44) {
        apresentarMensagemAlerta('A Chave de acesso precisa ter 44 dígitos!');
        return;
      }

      state.consultandoNFe = true;
      state.recebimentoMercadoria = await servicoNotaFiscal.consultaSefazXml(state.chaveAcesso, props.empresa);
      if (!validarConsultaNFe()) {
        // Adiciona um delay de 1 segundo
        await delay(1000);
        state.recebimentoMercadoria = await servicoNotaFiscal.consultaSefazXml(state.chaveAcesso, props.empresa);
      }

      if (!validarConsultaNFe()) {
        // Adiciona um delay de 1 segundo
        await delay(1000);
        state.recebimentoMercadoria = await servicoNotaFiscal.consultaSefazXml(state.chaveAcesso, props.empresa);
      }

      state.consultandoNFe = false;

      if (validarConsultaNFe()) {
        emit('importarNfe', state.recebimentoMercadoria);
      } else {
        apresentarMensagemAlerta('Chave de acesso inválida ou não disponível na SEFAZ nacional. Tente novamente em alguns minutos.');
      }
    }

    async function colarChaveAcesso(evento:any) {
      let informacao = evento.clipboardData.getData('text');
      informacao = informacao.replaceAll(' ', '').trim();
      state.chaveAcesso = informacao;

      if (state.chaveAcesso.length === 44) {
        await consultarNotaFiscal();
      }
    }

    return {
      props,
      state,
      consultarNotaFiscal,
      colarChaveAcesso,
    };
  },
});

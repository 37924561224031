
import { defineComponent, reactive } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import { EOrigemRecebimentoMercadoria } from '@/models/Enumeradores/Compras/EOrigemRecebimentoMercadoria';
import RecebimentoMercadoriasImportarXml from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasImportarXml.vue';
import RecebimentoMercadoriasNotaContraCnpj from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasNotaContraCnpj.vue';
import RecebimentoMercadoriasImportarChaveAcesso from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasImportarChaveAcesso.vue';
import RecebimentoMercadoriasRevisarInformacoes from '@/components/Compras/RecebimentosMercadorias/RecebimentoMercadoriasRevisarInformacoes.vue';
import { IRecebimentoMercadoria } from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import { ICfop } from '@/models/Entidades/Cadastros/Tributacoes/ICfop';
import { IUnidade } from '@/models/Entidades/Cadastros/Produtos/IUnidade';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { INcm } from '@/models/Entidades/Cadastros/Produtos/INcm';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';

export default defineComponent({
  name: 'SelecionarOrigemRecebimentoMercadoriasModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    RecebimentoMercadoriasImportarXml,
    RecebimentoMercadoriasNotaContraCnpj,
    RecebimentoMercadoriasImportarChaveAcesso,
    RecebimentoMercadoriasRevisarInformacoes,
  },
  emits: ['update:visivel', 'origemEscolhida', 'continuarImportacaoRecebimento'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const state = reactive({
      tituloModal: 'Novo Recebimento',
      widthModal: '700px',
      classModal: 'ss-modal-compras',
      apresentarSelecaoOrigem: true,
      apresentarRevisaoInformacoes: false,
      apresentarImportacaoXml: false,
      apresentarNotasContraCnpj: false,
      apresentarImportacaoChaveAcesso: false,
      cadastrarFornecedor: false,
      cadastrarTransportadora: false,
      recebimentoMercadoria: {} as IRecebimentoMercadoria,
      tiposCustosCadastrados: [] as ITipoCusto[],
      cfopsCadastrados: [] as ICfop[],
      unidadesCadastradas: [] as IUnidade[],
      ncmsCadastrados: [] as INcm[],
      codigoFornecedor: 0,
      importandoRecebimento: false,
      apresentarAcoes: false,
    });

    function continuarImportacaoRecebimento() {
      if (!UtilitarioGeral.validaCodigo(state.recebimentoMercadoria.notaFiscal.emitente.codigoPessoa)) {
        apresentarMensagemAlerta('É necessário selecionar o Fornecedor!');
        return;
      }

      if (!UtilitarioData.validaDataPreenchida(state.recebimentoMercadoria.notaFiscal.dataEntradaSaida)) {
        apresentarMensagemAlerta('É necessário preencher a Data de Entrada!');
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.recebimentoMercadoria.codigoFormaPagamento)) {
        apresentarMensagemAlerta('É necessário selecionar a Forma de Pagamento em Detalhes de Pagamento!');
        return;
      }

      if (UtilitarioGeral.validaLista(state.recebimentoMercadoria.notaFiscal.detalhesPagamento)) {
        let detalhePagamento = state.recebimentoMercadoria.notaFiscal.detalhesPagamento.find((c) => c.numeroTitulo === '' || c.numeroTitulo === undefined);
        if (detalhePagamento !== undefined) {
          apresentarMensagemAlerta('É necessário informar o número do título em Detalhes de Pagamento.');
          return;
        }

        detalhePagamento = state.recebimentoMercadoria.notaFiscal.detalhesPagamento.find((c) => c.data === '' || c.data === undefined);
        if (detalhePagamento !== undefined) {
          apresentarMensagemAlerta(`É necessário informar a Data/Vencimento no título: ${detalhePagamento.numeroTitulo} em Detalhes de Pagamento.`);
          return;
        }

        detalhePagamento = state.recebimentoMercadoria.notaFiscal.detalhesPagamento.find((c) => c.codigoTipoDocumentoFinanceiro === 0 || c.codigoTipoDocumentoFinanceiro === undefined);
        if (detalhePagamento !== undefined) {
          apresentarMensagemAlerta(`É necessário informar o tipo de Documento no título: ${detalhePagamento.numeroTitulo} em Detalhes de Pagamento.`);
          return;
        }

        detalhePagamento = state.recebimentoMercadoria.notaFiscal.detalhesPagamento.find((c) => c.codigoConta === 0 || c.codigoConta === undefined);
        if (detalhePagamento !== undefined) {
          apresentarMensagemAlerta(`É necessário informar a Conta no título: ${detalhePagamento.numeroTitulo} em Detalhes de Pagamento.`);
          return;
        }

        detalhePagamento = state.recebimentoMercadoria.notaFiscal.detalhesPagamento.find((c) => c.codigoPlanoContaCategoria === 0 || c.codigoPlanoContaCategoria === undefined);
        if (detalhePagamento !== undefined) {
          apresentarMensagemAlerta(`É necessário informar a Categoria do Plano de contas no título: ${detalhePagamento.numeroTitulo} em Detalhes de Pagamento.`);
          return;
        }
      }

      if (UtilitarioGeral.validaLista(state.recebimentoMercadoria.itens)) {
        const indexProduto = state.recebimentoMercadoria.itens.findIndex((c) => c.itemNotaFiscal.codigoProdutoDefinicao === 0 || c.itemNotaFiscal.codigoProdutoDefinicao === undefined);
        if (indexProduto >= 0) {
          apresentarMensagemAlerta(`É necessário vincular o produto: ${state.recebimentoMercadoria.itens[indexProduto].descricaoProdutoNfFornecedor} com algum cadastro do sistema.`);
          return;
        }

        const indexProdutoDescricao = state.recebimentoMercadoria.itens.findIndex((c) => c.itemNotaFiscal.descricao === '' || c.itemNotaFiscal.descricao === undefined);
        if (indexProdutoDescricao >= 0) {
          apresentarMensagemAlerta(`É necessário selecionar o produto do item:${indexProdutoDescricao + 1}`);
          return;
        }

        const produto = state.recebimentoMercadoria.itens.find((c) => c.itemNotaFiscal.codigoUnidade === 0 || c.itemNotaFiscal.codigoUnidade === undefined);
        if (produto !== undefined) {
          apresentarMensagemAlerta(`O produto: ${produto.itemNotaFiscal.descricao} não possui uma unidade selecionada.`);
          return;
        }

        const produtoValor = state.recebimentoMercadoria.itens.find((c) => c.valorTotalNfFornecedor !== c.itemNotaFiscal.valorTotal);
        if (produtoValor !== undefined) {
          apresentarMensagemAlerta(`O total do produto: ${produtoValor.itemNotaFiscal.descricao} difere do Valor Total da NF-e.`);
          return;
        }

        const produtoCfop = state.recebimentoMercadoria.itens.find((c) => c.itemNotaFiscal.impostos.codigoCfop > 0);
        if (produtoCfop !== undefined) {
          state.recebimentoMercadoria.codigoCfop = produtoCfop.itemNotaFiscal.impostos.codigoCfop;
        }
      } else {
        apresentarMensagemAlerta('É necessário preencher pelo menos um item.');
        return;
      }

      emit('continuarImportacaoRecebimento', state.recebimentoMercadoria);
      modalBase.computedVisivel = false;
    }

    async function criarRecebimentoPorNotaFiscal(recebimento: IRecebimentoMercadoria) {
      state.importandoRecebimento = true;
      state.recebimentoMercadoria = recebimento;
      state.apresentarImportacaoXml = false;
      state.apresentarNotasContraCnpj = false;
      state.apresentarImportacaoChaveAcesso = false;
      state.importandoRecebimento = false;
      state.classModal = 'full-modal ss-modal-compras';
      state.widthModal = '100%';
      state.apresentarRevisaoInformacoes = true;
    }

    function escolherOrigem(origemEscolhida: EOrigemRecebimentoMercadoria) {
      switch (origemEscolhida) {
        case EOrigemRecebimentoMercadoria.Manual:
          emit('origemEscolhida', origemEscolhida);
          modalBase.computedVisivel = false;
          break;
        case EOrigemRecebimentoMercadoria.NotasContraCnpj:
          state.widthModal = '1200px';
          state.apresentarNotasContraCnpj = true;
          state.apresentarSelecaoOrigem = false;
          state.tituloModal = 'Novo Recebimento - Notas Contra CNPJ';
          break;
        case EOrigemRecebimentoMercadoria.ImportacaoXML:
          state.apresentarImportacaoXml = true;
          state.apresentarSelecaoOrigem = false;
          state.tituloModal = 'Novo Recebimento - Importar XML';
          break;

        case EOrigemRecebimentoMercadoria.ConsultaChave:
          state.apresentarImportacaoChaveAcesso = true;
          state.apresentarSelecaoOrigem = false;
          state.tituloModal = 'Novo Recebimento - Importando pela Chave de Acesso';
          break;

        default:
          break;
      }
    }
    return {
      props,
      state,
      telaBase,
      modalBase,
      EOrigemRecebimentoMercadoria,
      escolherOrigem,
      criarRecebimentoPorNotaFiscal,
      continuarImportacaoRecebimento,
    };
  },
});


import {
  computed, defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { useMagicKeys } from '@vueuse/core';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarProdutoMovimento from '@/components/Cadastros/Produtos/SelecionarProdutoMovimento.vue';
import SelecionarEstoqueProdutoMovimento from '@/components/Cadastros/Estoques/SelecionarEstoqueProdutoMovimento.vue';
import SelecionarUnidadeProdutoMovimento from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidadeProdutoMovimento.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { IRecebimentoMercadoriaItem, IRecebimentoMercadoriaItemPreco } from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import RecebimentoMercadoriasItemModal from '@/views/Compras/RecebimentoMercadorias/RecebimentoMercadoriasItemModal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import {
  INotaFiscalItem, INotaFiscalItemImposto, INotaFiscalItemImpostoIcms, INotaFiscalItemImpostoIpi, INotaFiscalItemImpostoPis, INotaFiscalItemImpostoCofins, INotaFiscalItemImpostoSubstituicaoTributaria, INotaFiscalItemImpostoImportacao, INotaFiscalItemMedicamento, INotaFiscalItemCombustivel, INotaFiscalItemEstoque,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { EOrigemValorItem } from '@/models/Enumeradores/Compartilhados/EOrigemValorItem';
import { IDTOUnidadeProdutoMovimento } from '@/models/DTO/Cadastros/Produtos/IDTOUnidadeProdutoMovimento';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useFocusBase } from '@/core/composables/FocusBase';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import ServicoRecebimentoMercadoria from '@/servicos/Compras/ServicoRecebimentoMercadoria';
import { ICfop } from '@/models/Entidades/Cadastros/Tributacoes/ICfop';

export default defineComponent({
  name: 'RecebimentoMercadoriasItens',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    pessoa: {
      type: Number,
      required: true,
    },
    cfop: {
      type: Object as () => ICfop,
      required: true,
    },
    estoquesDisponiveis: {
      type: Array as () => IEstoque[],
      required: true,
    },
    totalNota: {
      type: Number,
      required: true,
    },
    itens: {
      type: Array as () => IRecebimentoMercadoriaItem[],
      required: true,
    },
    tiposCustosCadastrados: {
      type: Array as () => ITipoCusto[],
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    novoItem: {
      type: Boolean,
      default: false,
    },
    carregandoItensPelaImportacao: {
      type: Boolean,
      default: false,
    },
    chaveItens: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Icone,
    CampoNumerico,
    SelecionarProdutoMovimento,
    SelecionarUnidadeProdutoMovimento,
    SelecionarEstoqueProdutoMovimento,
    MensagemSemDados,
    RecebimentoMercadoriasItemModal,
  },
  emits: ['update:itens', 'atualizarItem', 'excluirItem', 'update:novoItem', 'update:carregandoItensPelaImportacao'],
  setup(props, { emit }) {
    const servicoRecebimentoMercadoria = new ServicoRecebimentoMercadoria();

    let debounce = 0;
    let debounceRenderizarGrade = 0;

    const keys = useMagicKeys();
    const shiftN = keys['Shift+N'];
    const {
      focusBase, elementosFocaveis, obtemIndexElemento, focusElemento, proximoElemento, elementoAnterior, elementoAcima, elementoAbaixo,
    } = useFocusBase();
    focusBase.classElementos = 'ss-recebimento-mercadoria-itens';

    const state = reactive({
      colunasGrade: [] as IColumn[],
      indexItemSelecionado: -1,
      itemSelecionado: {} as IRecebimentoMercadoriaItem,
      existeProximo: false,
      existeAnterior: false,
      exibirDetalhesItem: false,
      indexDetalhesEstoques: -1,
      apresentarEstoquesItem: false,
      inputFocusEstoqueItem: false,
      popOverHoverEstoqueItem: false,
      carregando: false,
      telaMobile: false,
      obtendoEstoque: false,
      computedNovoItem: computed({
        get: () => props.novoItem,
        set: (val: boolean) => {
          emit('update:novoItem', val);
        },
      }),
      dadosUnidadesSelecionadas: [] as IDTOUnidadeProdutoMovimento [],
    });

    const computedItens = computed({
      get: () => props.itens,
      set: (val: IRecebimentoMercadoriaItem[]) => {
        emit('update:itens', val);
      },
    });

    const computedCarregandoItensPelaImportacao = computed({
      get: () => props.carregandoItensPelaImportacao,
      set: (val: boolean) => {
        emit('update:carregandoItensPelaImportacao', val);
      },
    });

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Item', dataIndex: 'item', key: 'Item', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasGrade = [
          {
            title: 'Produto', dataIndex: 'produto', key: 'Produto', position: 1, visible: true, width: 210, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Unidade', dataIndex: 'unidade', key: 'Unidade', position: 3, visible: true, width: 40, ordering: false, align: 'center', ellipsis: true,
          },
          {
            title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 3, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true,
          },
          {
            title: 'V. Unitário', dataIndex: 'valorUnitario', key: 'ValorUnitario', position: 4, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true,
          },
          {
            title: 'ICMS', dataIndex: 'icms', key: 'Icms', position: 5, visible: true, align: 'center', width: 40, ellipsis: true,
          },
          {
            title: 'IPI', dataIndex: 'ipi', key: 'Ipi', position: 6, visible: true, ordering: false, align: 'center', width: 40, ellipsis: true,
          },
          {
            title: 'Desconto', dataIndex: 'desconto', key: 'Desconto', position: 7, visible: true, ordering: false, align: 'right', width: 60, ellipsis: true,
          },
          {
            title: 'Valor Total', dataIndex: 'total', key: 'Total', position: 8, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 0, visible: true, fixed: 'right', width: 30, align: 'center', ellipsis: true,
          },
        ];
      }
    }

    function validarExisteProximoItem() {
      state.existeProximo = state.indexItemSelecionado < computedItens.value.length - 1;
    }

    function validarExisteItemAnterior() {
      state.existeAnterior = state.indexItemSelecionado > 0;
    }

    function instanciaNovoItem():IRecebimentoMercadoriaItem {
      const itemRecebimentoMercadoria = {} as IRecebimentoMercadoriaItem;
      itemRecebimentoMercadoria.emDigitacao = false;
      itemRecebimentoMercadoria.custos = servicoRecebimentoMercadoria.criaCustoItemRecebimento(props.empresa, props.tiposCustosCadastrados);
      itemRecebimentoMercadoria.precos = [] as IRecebimentoMercadoriaItemPreco[];
      itemRecebimentoMercadoria.itemNotaFiscal = {} as INotaFiscalItem;
      itemRecebimentoMercadoria.itemNotaFiscal.estoquesDisponiveis = [] as IEstoque[];
      itemRecebimentoMercadoria.itemNotaFiscal.codigoProdutoDefinicao = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.codigoProdutoDefinicao = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.codigoUnidadePrincipal = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.fatorUnidadePrincipal = 1;
      itemRecebimentoMercadoria.itemNotaFiscal.codigoUnidade = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.fracionarUnidade = false;
      itemRecebimentoMercadoria.itemNotaFiscal.siglaUnidade = '';
      itemRecebimentoMercadoria.itemNotaFiscal.fatorConversao = 1;
      itemRecebimentoMercadoria.itemNotaFiscal.casasDecimaisUnidade = 2;
      itemRecebimentoMercadoria.itemNotaFiscal.precoUnitario = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.casasDecimaisPrecoUnitario = 2;
      itemRecebimentoMercadoria.itemNotaFiscal.quantidade = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.valorDesconto = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.valorTotal = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.movimentarEstoque = true;
      itemRecebimentoMercadoria.itemNotaFiscal.compoeTotal = true;
      itemRecebimentoMercadoria.itemNotaFiscal.origemFrete = EOrigemValorItem.Rateio;
      itemRecebimentoMercadoria.itemNotaFiscal.origemOutrasDespesas = EOrigemValorItem.Rateio;
      itemRecebimentoMercadoria.itemNotaFiscal.origemSeguro = EOrigemValorItem.Rateio;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos = {} as INotaFiscalItemImposto;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.icms = {} as INotaFiscalItemImpostoIcms;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cst = '00';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.origemMercadoria = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.icms.modalidadeBaseCalculoIcms = 3;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.icms.valorIcms = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi = {} as INotaFiscalItemImpostoIpi;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi.cstIpi = '52';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi.tipoCalculoIpi = 1;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi.codigoEnquadramentoIpi = '999';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.ipi.valorIpi = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.pis = {} as INotaFiscalItemImpostoPis;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.pis.cstPis = '07';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.pis.tipoCalculoPis = 1;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.pis.valorPis = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cofins = {} as INotaFiscalItemImpostoCofins;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cofins.cstCofins = '07';
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cofins.tipoCalculoCofins = 1;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.cofins.valorCofins = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.st = {} as INotaFiscalItemImpostoSubstituicaoTributaria;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.importacao = {} as INotaFiscalItemImpostoImportacao;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.importacao.valorImportacao = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.importacao.valorIof = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.impostos.importacao.despesasAduaneiras = 0;
      itemRecebimentoMercadoria.itemNotaFiscal.medicamento = {} as INotaFiscalItemMedicamento;
      itemRecebimentoMercadoria.itemNotaFiscal.combustivel = {} as INotaFiscalItemCombustivel;
      itemRecebimentoMercadoria.itemNotaFiscal.estoques = [] as INotaFiscalItemEstoque[];
      return itemRecebimentoMercadoria;
    }

    function adicionarItem() {
      state.indexItemSelecionado = -1;
      state.itemSelecionado = instanciaNovoItem();
      validarExisteProximoItem();
      validarExisteItemAnterior();
      state.exibirDetalhesItem = true;
    }

    function editarItem(index: number) {
      state.indexItemSelecionado = index;
      state.itemSelecionado = computedItens.value[index];
      validarExisteProximoItem();
      validarExisteItemAnterior();
      state.exibirDetalhesItem = true;
    }

    function proximoItem() {
      editarItem(state.indexItemSelecionado + 1);
    }

    function itemAnterior() {
      editarItem(state.indexItemSelecionado - 1);
    }

    function salvarItemGrade(index: number, item: IRecebimentoMercadoriaItem) {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        computedItens.value[index] = item;
        emit('atualizarItem', index);
      }, 600);
    }

    function salvarItem(item: IRecebimentoMercadoriaItem) {
      if (state.indexItemSelecionado > -1) {
        computedItens.value[state.indexItemSelecionado] = item;
      } else {
        computedItens.value.push(item);
        state.indexItemSelecionado = computedItens.value.length - 1;
      }
      state.exibirDetalhesItem = false;
      emit('atualizarItem', state.indexItemSelecionado);
    }

    function trataInformacoesEspecificasProduto(index: number) {
      if (!computedCarregandoItensPelaImportacao.value) {
        computedItens.value[index].itemNotaFiscal.estoques = [];
        computedItens.value[index].precos = [];
        computedItens.value[index].itemNotaFiscal.impostos.codigoCfop = props.cfop.codigo;
        computedItens.value[index].itemNotaFiscal.impostos.cfop = props.cfop.codigoCfop;
      }
    }

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      preencherColunas();
    });

    watch(async () => state.computedNovoItem, async () => {
      if (state.computedNovoItem) {
        adicionarItem();
        state.computedNovoItem = false;
      }
    });

    async function novoItemGrade() {
      const itens = props.itens;
      if (UtilitarioGeral.validaLista(itens)) {
        let ultimoIndex = 0;
        if (itens.length === 1) {
          ultimoIndex = 0;
        } else {
          ultimoIndex = itens.length - 1;
        }
        if (UtilitarioGeral.validaCodigo(itens[ultimoIndex].itemNotaFiscal.codigoProdutoDefinicao)) {
          const itemRecebimentoMercadoria = instanciaNovoItem();
          itens.push(itemRecebimentoMercadoria);
          computedItens.value = itens;
        }
      } else {
        const itemRecebimentoMercadoria = instanciaNovoItem();
        itens.push(itemRecebimentoMercadoria);
        computedItens.value = itens;
      }

      setTimeout(() => {
        focusElemento(elementosFocaveis.value.length - 6);
      }, 300);
    }

    function excluirItem(index: number) {
      const codigoItem = computedItens.value[index].codigo;
      computedItens.value.splice(index, 1);
      emit('excluirItem', codigoItem);
    }

    async function confirmarExclusao(index: number, evento?:any) {
      if (UtilitarioGeral.validaCodigo(props.itens[index].itemNotaFiscal.codigoProdutoDefinicao)) {
        Modal.confirm({
          title: 'Você confirma a exclusão do item?',
          content: `${props.itens[index].itemNotaFiscal.descricao}`,
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: 'ok',
          onOk: async () => {
            let indexCampo = -1;
            const ultimoItem = (index === computedItens.value.length - 1);
            if (evento !== undefined) {
              indexCampo = obtemIndexElemento(evento);
            }
            await excluirItem(index);
            if (UtilitarioGeral.validaLista(computedItens.value)) {
              if (ultimoItem) {
                focusElemento(elementosFocaveis.value.length - 7);
              } else if (indexCampo !== undefined && indexCampo >= 0) {
                focusElemento(indexCampo);
              }
            }
          },
        });
      } else {
        await excluirItem(index);
      }
    }

    watch(shiftN, () => {
      novoItemGrade();
    });

    function verificaElementoEsquerda(emDigitacao:boolean, evento:any) {
      if (!emDigitacao) {
        elementoAnterior(evento);
      }
    }

    function verificaElementoDireita(emDigitacao:boolean, evento:any) {
      if (!emDigitacao) {
        proximoElemento(evento);
      }
    }
    function verificaElementoAcima(emDigitacao:boolean, evento:any, dataAttributeColuna:string) {
      if (!emDigitacao) {
        elementoAcima(evento, dataAttributeColuna);
      }
    }

    function verificaElementoAbaixo(emDigitacao:boolean, evento:any, dataAttributeColuna:string) {
      if (!emDigitacao) {
        elementoAbaixo(evento, dataAttributeColuna);
      }
    }

    function verificaElementoProximoElementoDesconto(emDigitacao: boolean, index: number, evento: any) {
      if (index === computedItens.value.length - 1) {
        novoItemGrade();
        clearTimeout(debounceRenderizarGrade);
        debounceRenderizarGrade = setTimeout(async () => {
          proximoElemento(evento);
        }, 150);
      } else {
        proximoElemento(evento);
      }
    }

    function quantidadeFoco(index: number) {
      state.indexDetalhesEstoques = index;
      state.apresentarEstoquesItem = true;
    }

    function quantidadeBlur() {
      if (!state.popOverHoverEstoqueItem && !state.inputFocusEstoqueItem) {
        state.indexDetalhesEstoques = -1;
        state.apresentarEstoquesItem = false;
      }
    }

    function estrategiaBuscarDadosProdutosAtualizados(): boolean {
      if (computedCarregandoItensPelaImportacao.value) {
        return false;
      }
      return props.editavel;
    }

    return {
      props,
      state,
      computedItens,
      UtilitarioMascara,
      trataInformacoesEspecificasProduto,
      proximoItem,
      itemAnterior,
      adicionarItem,
      editarItem,
      confirmarExclusao,
      salvarItem,
      salvarItemGrade,
      focusElemento,
      proximoElemento,
      elementoAnterior,
      elementoAbaixo,
      elementoAcima,
      novoItemGrade,
      verificaElementoEsquerda,
      verificaElementoDireita,
      verificaElementoAcima,
      verificaElementoAbaixo,
      verificaElementoProximoElementoDesconto,
      quantidadeFoco,
      quantidadeBlur,
      estrategiaBuscarDadosProdutosAtualizados,
    };
  },
});

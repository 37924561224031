
import { defineComponent, reactive } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { IRecebimentoMercadoria } from '@/models/Entidades/Compras/IRecebimentoMercadoria';

export default defineComponent({
  name: 'RecebimentoMercadoriasImportarXml',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
  },
  emits: ['importarNfe'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();
    const state = reactive({
      enviandoXml: false,
      recebimentoMercadoria: {} as IRecebimentoMercadoria,
      chaveUpload: 1,
    });

    const servicoNotaFiscal = new ServicoNotaFiscal();

    function validarImportacaoXml() {
      if (!UtilitarioGeral.valorValido(state.recebimentoMercadoria.notaFiscal.chaveAcesso)) {
        apresentarMensagemAlerta('Arquivo inválido ou layout incompatível com a NF-e.');
        state.chaveUpload += 1;
        return;
      }

      emit('importarNfe', state.recebimentoMercadoria);
    }

    function selecionarXmlUpload() {
      const elemento = document.getElementById('selecionarXmlNFe');
      if (elemento !== null) {
        elemento.click();
      }
    }

    async function enviarXml(event:any) {
      state.enviandoXml = true;
      state.recebimentoMercadoria = await servicoNotaFiscal.importarXml(event.target.files[0], props.empresa);
      validarImportacaoXml();
      state.enviandoXml = false;
    }

    async function drop(event:any) {
      state.enviandoXml = true;
      state.recebimentoMercadoria = await servicoNotaFiscal.importarXml(event.dataTransfer.files[0], props.empresa);
      validarImportacaoXml();
      state.enviandoXml = false;
    }

    return {
      props,
      state,
      drop,
      enviarXml,
      selecionarXmlUpload,
    };
  },
});

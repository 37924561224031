import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = {
  class: "ant-col ant-col-xs-24 ant-col-xl-24",
  style: {"margin-bottom":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_tipo_pagamento_devolucao = _resolveComponent("selecionar-tipo-pagamento-devolucao")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_devolucao_compras_pagamento_credito = _resolveComponent("devolucao-compras-pagamento-credito")!
  const _component_devolucao_compras_pagamento_titulos_receber = _resolveComponent("devolucao-compras-pagamento-titulos-receber")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_form_item, {
          label: "Tipo de devolução do pagamento",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_selecionar_tipo_pagamento_devolucao, {
              valor: _ctx.computedTipoDevolucaoPagamento,
              "onUpdate:valor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedTipoDevolucaoPagamento) = $event)),
              onChange: _ctx.alterarTipoDevolucao,
              disabled: !_ctx.props.editavel
            }, null, 8, ["valor", "onChange", "disabled"])
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.props.tipoDevolucaoPagamento === 2)
      ? (_openBlock(), _createBlock(_component_devolucao_compras_pagamento_credito, {
          key: 0,
          detalheCredito: _ctx.computedDetalheCredito,
          "onUpdate:detalheCredito": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedDetalheCredito) = $event)),
          editavel: _ctx.props.editavel,
          empresa: _ctx.props.empresa
        }, null, 8, ["detalheCredito", "editavel", "empresa"]))
      : _createCommentVNode("", true),
    (_ctx.props.tipoDevolucaoPagamento === 4)
      ? (_openBlock(), _createBlock(_component_devolucao_compras_pagamento_titulos_receber, {
          key: 1,
          detalhesPagamento: _ctx.computedDetalhesPagamento,
          "onUpdate:detalhesPagamento": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedDetalhesPagamento) = $event)),
          editavel: _ctx.props.editavel,
          empresa: _ctx.props.empresa,
          totalDetalhesPagamento: _ctx.props.totalDetalhesPagamento,
          onAtualizarDetalhePagamento: _ctx.atualizarDetalhePagamento
        }, null, 8, ["detalhesPagamento", "editavel", "empresa", "totalDetalhesPagamento", "onAtualizarDetalhePagamento"]))
      : _createCommentVNode("", true)
  ], 64))
}
import ApiERP from '@/core/conectores/ApiERP';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import {
  IRecebimentoMercadoria, IRecebimentoMercadoriaItem, IRecebimentoMercadoriaItemCusto, IRecebimentoMercadoriaItemPreco,
} from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import storeSistema from '@/store/storeSistema';
import { EStatusRecebimentoMercadoria } from '@/models/Enumeradores/Compras/EStatusRecebimentoMercadoria';
import { IParametrosConsultaRecebimentoMercadoria } from '@/models/ParametrosRequisicao/Compras/IParametrosConsultaRecebimentoMercadoria';
import { INotaFiscalIncidenciaCalculo } from '@/models/Entidades/Fiscal/INotaFiscal';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import { ETipoItem } from '@/models/Enumeradores/Compartilhados/ETipoItem';
import { IDTORecebimentoMercadoriasBuscaFinanceiro } from '@/models/DTO/Compras/IDTORecebimentoMercadoriasBuscaFinanceiro';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';

/**
 * Serviço de Recebimentos de Mercadorias
 * Fornece todas regras de negócios e lógicas relacionado a recebimentos de mercadorias.
 */

class ServicoRecebimentoMercadoria {
    endPoint = 'recebimentos-mercadorias';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsultaRecebimentoMercadoria, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      let parametrosAdicionais = '';
      if (parametrosConsulta.empresas !== undefined && parametrosConsulta.empresas.length > 0) {
        parametrosConsulta.empresas.forEach((codigoEmpresa) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `Empresas=${codigoEmpresa}`;
        });
      }

      if (parametrosConsulta.periodo !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

        parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
        parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
      }

      if (parametrosConsulta.valorBuscaRapida !== undefined) {
        if (UtilitarioGeral.valorValido(parametrosConsulta.valorBuscaRapida)) {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `valorBuscaRapida=${parametrosConsulta.valorBuscaRapida}`;
        }
      }

      if (filtros !== undefined) {
        const jsonFiltros = JSON.stringify(filtros);
        parametrosAdicionais += `&Filtros=${jsonFiltros}`;
      }
      if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
        parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
        parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
        parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
      }
      if (parametrosConsulta.ordenacao !== undefined) {
        parametrosConsulta.ordenacao.forEach((item) => {
          parametrosAdicionais += `&Ordenacao=${item}`;
        });
      }
      if (parametrosConsulta.codigosSelecionados !== undefined) {
        parametrosConsulta.codigosSelecionados.forEach((codigo) => {
          parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
        });
      }

      const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data;
      return listaPaginada;
    }

    public async buscaAvancadaItens(ignorarDevolvdos: boolean, codigoFornecedor: number, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      const parametrosEmpresas = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
      if (UtilitarioGeral.validaCodigo(codigoFornecedor)) {
        parametrosAdicionais += `&codigoFornecedor=${codigoFornecedor}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/itens/ignorar-devolvidos/${ignorarDevolvdos}${parametrosEmpresas}${parametrosAdicionais}`);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data;
      return listaPaginada;
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsultaRecebimentoMercadoria, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      const parametrosEmpresas = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
      if (parametrosConsulta.periodo !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;
        parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
        parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
      }
      if (codigosSelecionados !== undefined) {
        parametrosAdicionais += this.apiERP.obterParametroCodigosSelecionados(codigosSelecionados);
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/relatorio-padrao/${tipoArquivo}${parametrosEmpresas}${parametrosAdicionais}`);
      return result.data;
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IRecebimentoMercadoria): string {
      return String(objeto.codigo);
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async obterRecebimentoMercadoriaConsultaRapida(codigoRecebimento: number, empresas?: number[]): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigoRecebimento.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(empresas)) {
        parametrosConsultaRapida.empresas = empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      const listaRecebimentos = await this.consultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaLista(listaRecebimentos)) {
        return listaRecebimentos[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async incluir(recebimento: IRecebimentoMercadoria): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, recebimento);
      return result.data;
    }

    public async incluirCabecalho(recebimento: IRecebimentoMercadoria): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/cabecalho`, recebimento);
      return result.data;
    }

    public async alterar(recebimento: IRecebimentoMercadoria): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${recebimento.codigo}`, recebimento);
      return result.data;
    }

    public async atualizarCampoCabecalho(recebimento: IRecebimentoMercadoria, campo: string): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/campo/${campo}`, recebimento);
      return result.data;
    }

    public async atualizarItem(item: IRecebimentoMercadoriaItem, valorTotal: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/item/valor-total/${valorTotal}/empresa/${empresa}`, item);
      return result.data;
    }

    public async atualizarOrdenacao(ordenacaoRegistros: IOrdenacaoRegistro[], empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/ordenacao/empresa/${empresa}`, ordenacaoRegistros);
      return result.data;
    }

    public async alterarEtapa(codigo: number, empresa: number, statusNovo: EStatusRecebimentoMercadoria): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/etapa/${codigo}/empresa/${empresa}/status-novo/${statusNovo}`);
      return result.data;
    }

    public async alterarEtapaVarios(empresa: number, proximaEtapa: boolean, cancelamento: boolean, recebimentos: number[]): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < recebimentos.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Recebimentos=${recebimentos[contador]}`;
      }

      const result: any = await this.apiERP.put(`${this.endPoint}/etapa-varios/empresa/${empresa}/proxima-etapa/${proximaEtapa}/cancelamento/${cancelamento}${parametrosAdicionais}`);
      return result.data;
    }

    public async excluir(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluirVarios(empresa: number, recebimentos: number[]): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < recebimentos.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Recebimentos=${recebimentos[contador]}`;
      }

      const result: any = await this.apiERP.delete(`${this.endPoint}/varios/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async excluirItem(codigo: number, empresa: number, valorTotal: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/item/${codigo}/empresa/${empresa}/valor-total/${valorTotal}`);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IRecebimentoMercadoria> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async cancelar(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/cancelar/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterItem(codigoItem: number, empresa: number): Promise<IRecebimentoMercadoriaItem> {
      const result: any = await this.apiERP.get(`${this.endPoint}/item/${codigoItem}/empresa/${empresa}`);
      return result.data;
    }

    public async obterItens(recebimento: number, notaFiscal: number, empresa: number): Promise<IRecebimentoMercadoriaItem[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/itens/${recebimento}/nota-fiscal/${notaFiscal}/empresa/${empresa}`);
      return result.data;
    }

    public async obterItensRecebimentoCompletos(recebimento: number, empresa: number): Promise<IRecebimentoMercadoriaItem[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/itens-completos/${recebimento}/empresa/${empresa}`);
      return result.data;
    }

    public async obterItensCompletos(empresa: number, codigos: number[]): Promise<IRecebimentoMercadoriaItem[]> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < codigos.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigos[contador]}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/itens/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterCustosItem(codigoItem: number, empresa: number): Promise<IRecebimentoMercadoriaItemCusto[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/custos/${codigoItem}/empresa/${empresa}`);
      return result.data;
    }

    public async obterTitulosFinanceiros(buscaFinanceiro: IDTORecebimentoMercadoriasBuscaFinanceiro): Promise<IDTOTituloFinanceiro[]> {
      const result: any = await this.apiERP.post(`${this.endPoint}/titulos-financeiros/busca`, buscaFinanceiro);
      return result.data;
    }

    public async obterPrecoItem(codigoItem: number, empresa: number): Promise<IRecebimentoMercadoriaItemPreco> {
      const result: any = await this.apiERP.get(`${this.endPoint}/preco-item/${codigoItem}/empresa/${empresa}`);
      return result.data;
    }

    public async calcularAcrescimoMarkupItem(preco: string, markup: string): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/calcular-acrescimo-markup-item/${preco}/markup/${markup}`);
      return result.data;
    }

    public async calcularCustoItem(empresa: number, item: IRecebimentoMercadoriaItem): Promise<number> {
      const result: any = await this.apiERP.post(`${this.endPoint}/calcular-custo-item/empresa/${empresa}`, item);
      return result.data;
    }

    public async imprimirRecebimento(codigo: number, empresa: number, tipoArquivo: ETipoArquivo): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.get(`${this.endPoint}/impressao/${codigo}/empresa/${empresa}/tipo-arquivo/${tipoArquivo}`);
      return result.data;
    }

    public criaCustoItemRecebimento(empresa:number, tiposCustosCadastrados: ITipoCusto[]):IRecebimentoMercadoriaItemCusto[] {
      const custosProdutos: IRecebimentoMercadoriaItemCusto[] = [];
      const tipoCustosCadastrados = tiposCustosCadastrados.filter((c) => c.ativo === true && c.tipoItem === ETipoItem.Produto && c.recursoAtualizacao === 'RECEBIMENTOS_MERCADORIAS');
      let ordem = 1;
      tipoCustosCadastrados.forEach((tipoCusto) => {
        if (tipoCusto.empresas.some((c) => c.codigoEmpresa === empresa)) {
          const custoProduto:IRecebimentoMercadoriaItemCusto = {} as IRecebimentoMercadoriaItemCusto;
          custoProduto.codigo = 0;
          custoProduto.codigoRecebimentoMercadoriaItem = 0;
          custoProduto.codigoTipoCusto = tipoCusto.codigo;
          custoProduto.casasDecimais = tipoCusto.casasDecimais;
          custoProduto.formula = tipoCusto.formula;
          custoProduto.valorCusto = 0;
          custoProduto.ordem = ordem;
          ordem += 1;
          custosProdutos.push(custoProduto);
        }
      });

      return custosProdutos;
    }

    public obterIncidenciasNotaFiscalRecebimentoPadrao(): INotaFiscalIncidenciaCalculo[] {
      const incidenciasCalculo:INotaFiscalIncidenciaCalculo[] = [
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Seguro', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'IPI', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Desconto', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Seguro', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Desconto', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Seguro', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Desconto', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Seguro', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Desconto', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Seguro', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'IPI', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Desconto', valor: true,
        },
      ];

      return incidenciasCalculo;
    }
}
export default ServicoRecebimentoMercadoria;


import {
  defineComponent, computed, reactive, onBeforeMount,
} from 'vue';
import Icone from './Icone.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';

export default defineComponent({
  name: 'BuscaRapida',
  props: {
    buscaRapida: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Busca rápida: digite uma palavra ou um valor para acharmos seu registro...',
    },
  },
  emits: ['update:buscaRapida', 'buscarDados'],
  components: {
    Icone,
  },
  setup(props, { emit }) {
    let debounce = 0;
    const state = reactive({
      mobile: false,
    });

    const comptedBuscaRapida = computed({
      get: () => props.buscaRapida,
      set: (val) => {
        emit('update:buscaRapida', val);
      },
    });

    function buscarDados() {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        emit('buscarDados');
      }, 600);
    }

    function limparBuscaRapida() {
      comptedBuscaRapida.value = '';
      emit('buscarDados');
    }

    onBeforeMount(async () => {
      state.mobile = UtilitarioDispositivo.larguraTelaMobile();
    });

    return {
      props,
      state,
      comptedBuscaRapida,
      buscarDados,
      limparBuscaRapida,
    };
  },
});

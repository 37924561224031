
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import { INotaFiscalDetalhePagamento } from '@/models/Entidades/Fiscal/INotaFiscal';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';

export default defineComponent({
  name: 'DevolucaoComprasPagamentoCredito',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    detalheCredito: {
      type: Object as () => INotaFiscalDetalhePagamento,
      required: true,
    },
  },
  components: {
    Card,
    CampoNumerico,
    SelecionarConta,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarCategoriaPlanoConta,
  },
  emits: ['update:detalheCredito', 'salvarCredito'],
  setup(props, { emit }) {
    const state = reactive({
      telaMobile: false,
    });

    const computedDetalheCredito = computed({
      get: () => props.detalheCredito,
      set: (val: INotaFiscalDetalhePagamento) => {
        emit('update:detalheCredito', val);
      },
    });

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
    });

    return {
      props,
      state,
      computedDetalheCredito,
    };
  },
});


import { defineComponent, onBeforeMount, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import SelecionarFiltroPeriodoMovimentoComercial from '@/components/Compartilhados/SelecionarFiltroPeriodoMovimentoComercial.vue';
import Icone from '@/core/components/Icone.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import storeSistema from '@/store/storeSistema';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { ICardKanban, ICardKanbanDetalhe, IColunaKanban } from '@/core/models/IKanban';
import Kanban from '@/core/components/Kanban/Kanban.vue';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import ServicoDevolucaoCompras from '@/servicos/Compras/ServicoDevolucaoCompras';
import { IDTODevolucaoCompras } from '@/models/DTO/Compras/IDTODevolucaoCompras';
import DevolucaoComprasListagem from '@/components/Compras/DevolucoesCompras/DevolucaoComprasListagem.vue';
import DevolucaoComprasModal from './DevolucaoComprasModal.vue';
import { EStatusDevolucaoCompras } from '@/models/Enumeradores/Compras/EStatusDevolucaoCompras';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import Card from '@/core/components/Tela/Card.vue';
import { EFiltroPeriodoMovimentoComercial } from '@/models/Enumeradores/Compartilhados/EFiltroPeriodoMovimentoComercial';
import { IParametrosConsultaDevolucaoCompras } from '@/models/ParametrosRequisicao/Compras/IParametrosConsultaDevolucaoCompras';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { IRetornoDevolucaoCompra } from '@/models/Retornos/DevolucaoCompras/IRetornoDevolucaoCompra';

export default defineComponent({
  name: 'DevolucaoComprasIndex',
  components: {
    Icone,
    Kanban,
    DevolucaoComprasModal,
    DevolucaoComprasListagem,
    SelecionarDataInicialFinal,
    SelecionarFiltroPeriodoMovimentoComercial,
    Card,
    TituloPadrao,
    BuscaGeral,
    PreviewPdf,
  },
  setup(props) {
    const servicoDevolucaoCompras = new ServicoDevolucaoCompras();
    const servicoNotaFiscal = new ServicoNotaFiscal();
    servicoNotaFiscal.requisicaoSistema();
    let debounce = 0;
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas,
      adicionarAtalho, removerAtalho, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();

    const state = reactive({
      identificadorRecurso: 'DEVOLUCOES_COMPRAS',
      identificadorPermissao: 'PER_DEVOLUCOES_COMPRAS',
      empresaSelecionada: 0,
      dataAtual: '',
      filtroPeriodo: 4,
      dataInicialSelecionada: '',
      dataFinalSelecionada: '',
      buscaRapida: '',
      colunas: [] as IColunaKanban[],
      filtros: [] as IFiltroGenerico[],
      selecionados: [] as number[],
      paginacao: {} as IPagination,
      cardSelecionado: '',
      devolucoes: [] as IDTODevolucaoCompras[],
      buscaAvancada: {} as IBuscaAvancada,
      exibirLancamento: false,
      operacaoLancamento: {} as ITelaOperacao,
      qtdSelecionados: 0,
      qtdSelecionadosConcluir: 0,
      qtdSelecionadosRetomar: 0,
      qtdSelecionadosCancelar: 0,
      qtdSelecionadosExcluir: 0,
      visualizarKanban: true,
      atualizandoCards: false,
      arquivosPdf: [] as IArquivoPdf[],
    });

    function atualizarQtdSelecionados() {
      state.qtdSelecionados = 0;
      state.qtdSelecionadosConcluir = 0;
      state.qtdSelecionadosCancelar = 0;
      state.qtdSelecionadosExcluir = 0;
      state.qtdSelecionadosRetomar = 0;
      state.colunas.forEach((c) => {
        if (c.qtdSelecionados !== undefined) {
          state.qtdSelecionados += c.qtdSelecionados;
          if (Number(c.identificador) === 1) {
            state.qtdSelecionadosExcluir += c.qtdSelecionados;
          }
          if (Number(c.identificador) < 3) {
            state.qtdSelecionadosConcluir += c.qtdSelecionados;
          }
          if (Number(c.identificador) < 4) {
            state.qtdSelecionadosCancelar += c.qtdSelecionados;
          }
          if (Number(c.identificador) > 1 && Number(c.identificador) < 4) {
            state.qtdSelecionadosRetomar += c.qtdSelecionados;
          }
        }
      });
    }

    function atualizarSelecionadosKanban() {
      state.colunas.forEach((c, index) => {
        state.colunas[index].qtdSelecionados = 0;
      });

      state.devolucoes.forEach((d) => {
        const indexColuna = state.colunas.findIndex((c) => c.identificador === String(d.status));
        const indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(d.codigo));
        const indexSelecionados = state.selecionados.findIndex((s) => s === d.codigo);
        if (indexSelecionados >= 0) state.colunas[indexColuna].qtdSelecionados += 1;
        state.colunas[indexColuna].cards[indexCard].selecionado = indexSelecionados >= 0;
      });
      atualizarQtdSelecionados();
    }

    function atualizarSelecionados(identificador: string) {
      atualizarQtdSelecionados();
      const indexSelecionados = state.selecionados.findIndex((s) => s === Number(identificador));
      if (indexSelecionados < 0) {
        state.selecionados.push(Number(identificador));
      } else {
        state.selecionados.splice(indexSelecionados, 1);
      }
    }

    function desmarcarSelecionado(codigo: number, status: number, statusNovo: number) {
      const indexSelecionados = state.selecionados.findIndex((s) => s === codigo);
      if (indexSelecionados >= 0) {
        let indexColuna = state.colunas.findIndex((c) => c.identificador === String(status));
        let indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(codigo));
        if (indexCard === -1) {
          indexColuna = state.colunas.findIndex((c) => c.identificador === String(statusNovo));
          indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(codigo));
        }
        if (state.colunas[indexColuna].qtdSelecionados > 0) state.colunas[indexColuna].qtdSelecionados -= 1;
        state.colunas[indexColuna].cards[indexCard].selecionado = false;
        state.selecionados.splice(indexSelecionados, 1);
        atualizarQtdSelecionados();
      }
    }

    function preencherColunas() {
      state.colunas = [] as IColunaKanban[];
      state.colunas[0] = {} as IColunaKanban;
      state.colunas[0].identificador = '1';
      state.colunas[0].titulo = 'Em digitação';
      state.colunas[0].cor = '#969696';
      state.colunas[0].textoTotalizador = 'Total';
      state.colunas[0].cards = [] as ICardKanban[];

      state.colunas[1] = {} as IColunaKanban;
      state.colunas[1].identificador = '2';
      state.colunas[1].titulo = 'Aguardando emissão';
      state.colunas[1].cor = '#d6c720';
      state.colunas[1].textoTotalizador = 'Total';
      state.colunas[1].cards = [] as ICardKanban[];

      state.colunas[2] = {} as IColunaKanban;
      state.colunas[2].identificador = '3';
      state.colunas[2].titulo = 'Concluídas';
      state.colunas[2].cor = '#1843b8';
      state.colunas[2].textoTotalizador = 'Total';
      state.colunas[2].cards = [] as ICardKanban[];

      state.colunas[3] = {} as IColunaKanban;
      state.colunas[3].identificador = '4';
      state.colunas[3].titulo = 'Canceladas';
      state.colunas[3].cor = '#cf2751';
      state.colunas[3].textoTotalizador = 'Total';
      state.colunas[3].cards = [] as ICardKanban[];
    }

    function limparCards() {
      state.colunas.forEach((c, index) => {
        state.colunas[index].qtdSelecionados = 0;
        state.colunas[index].cards = [] as ICardKanban[];
      });
    }

    function criarCard(indexColuna: number, devolucao: IDTODevolucaoCompras) {
      const card = {} as ICardKanban;
      const indexSelecionados = state.selecionados.findIndex((s) => s === devolucao.codigo);

      card.identificadorColuna = state.colunas[indexColuna].identificador;
      card.identificador = String(devolucao.codigo);
      card.titulo = `Nº: ${devolucao.numero}`;
      card.ordem = devolucao.ordem;
      card.selecionado = indexSelecionados >= 0;
      card.detalhes = [] as ICardKanbanDetalhe[];
      let indexDetalhe = 0;
      if (devolucao.numeroNotaFiscal > 0) {
        card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
        card.detalhes[indexDetalhe].titulo = 'Nota Fiscal:';
        card.detalhes[indexDetalhe].detalhe = devolucao.numeroNotaFiscal.toString();
        indexDetalhe += 1;
      }
      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Data de Emissão: ';
      card.detalhes[indexDetalhe].detalhe = UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(devolucao.dataEmissao);
      indexDetalhe += 1;

      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Fornecedor: ';
      card.detalhes[indexDetalhe].detalhe = devolucao.nomeRazaoSocialDestinatario.length > 29 ? devolucao.nomeRazaoSocialDestinatario.substring(0, 30) : devolucao.nomeRazaoSocialDestinatario;

      indexDetalhe += 1;
      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Total R$: ';
      card.detalhes[indexDetalhe].detalhe = UtilitarioMascara.mascararValor(devolucao.totalNotaFiscal, 2);
      card.valor = devolucao.totalNotaFiscal;

      state.colunas[indexColuna].cards.push(card);
      if (indexSelecionados >= 0) state.colunas[indexColuna].qtdSelecionados += 1;
    }

    function prencheDatasPeriodo() {
      switch (state.filtroPeriodo) {
        case EFiltroPeriodoMovimentoComercial.Hoje:
          state.dataInicialSelecionada = state.dataAtual;
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ontem:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          state.dataFinalSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          break;

        case EFiltroPeriodoMovimentoComercial.SemanaAtual:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 7);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.MesAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaMes(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaMes(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.AnoAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaAno(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaAno(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos15Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 15);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos30Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 30);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos60Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 60);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos90dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 90);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        default:
      }
    }

    async function buscarDevolucoes(ordenacao: IOrdenacao[] = []) {
      state.devolucoes = [];
      limparCards();
      state.filtros = [] as IFiltroGenerico[];
      state.buscaAvancada.filtrosAdicionados.forEach((item) => {
        state.filtros.push(item.filtro);
      });

      prencheDatasPeriodo();
      const parametrosConsulta = {} as IParametrosConsultaDevolucaoCompras;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.periodo = state.filtroPeriodo;
      parametrosConsulta.dataInicial = state.dataInicialSelecionada;
      parametrosConsulta.dataFinal = state.dataFinalSelecionada;
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      if (UtilitarioGeral.validaLista(ordenacao) && !state.visualizarKanban) {
        ordenacao.forEach((item) => {
          parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });
      } else {
        parametrosConsulta.ordenacao.push('Status', 'Ordem');
      }

      parametrosConsulta.qtdeRegistrosPagina = 1000;

      telaBase.carregando = true;
      const listaPaginada = await servicoDevolucaoCompras.buscaAvancada(parametrosConsulta, state.filtros);
      state.devolucoes = listaPaginada.dados;
      state.devolucoes.forEach((d) => {
        criarCard(state.colunas.findIndex((c) => c.identificador === String(d.status)), d);
      });
      telaBase.carregando = false;
    }

    async function atualizarOrdemCards(ordenacaoRegistros: IOrdenacaoRegistro[]) {
      await servicoDevolucaoCompras.atualizarOrdenacao(ordenacaoRegistros, state.empresaSelecionada);
    }

    function recriarColuna(identificador: string) {
      const index = state.colunas.findIndex((c) => c.identificador === identificador);

      state.colunas[index].cards = [] as ICardKanban[];
      state.colunas[index].qtdSelecionados = 0;
      state.devolucoes.forEach((d) => {
        if (d.status === Number(identificador)) {
          criarCard(index, d);
        }
      });
      atualizarQtdSelecionados();
    }

    function obterTituloColuna(identificador: string) {
      const index = state.colunas.findIndex((c) => c.identificador === identificador);
      return state.colunas[index].titulo;
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.empresaSelecionada);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      await buscarDevolucoes();
    }

    function selecionarCard(selecionado: string) {
      state.cardSelecionado = selecionado;
    }

    function incluir() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function editar(codigo: number) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Alterar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = codigo;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function clickEditar() {
      editar(Number(state.cardSelecionado));
    }

    async function alterarEtapa(devolucao: IDTODevolucaoCompras, novaEtapa: string) {
      let retorno = {} as IRetornoDevolucaoCompra;
      const statusAntigo = String(devolucao.status);
      const index = state.devolucoes.findIndex((d) => d.codigo === devolucao.codigo);
      desmarcarSelecionado(state.devolucoes[index].codigo, state.devolucoes[index].status, Number(novaEtapa));
      retorno = await servicoDevolucaoCompras.alterarEtapa(devolucao.codigo, devolucao.codigoEmpresa, Number(novaEtapa));
      if (retorno.status === 1) {
        state.devolucoes[index].status = Number(novaEtapa);
        if (UtilitarioGeral.valorValido(retorno.linkDocumentoFiscal)) {
          state.arquivosPdf = [{ nome: '', link: retorno.linkDocumentoFiscal } as IArquivoPdf];
        }
      } else if (retorno.status === 2) {
        apresentarMensagemAlerta(retorno.mensagem);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
      if (state.visualizarKanban) {
        recriarColuna(novaEtapa);
        recriarColuna(statusAntigo);
      } else {
        buscarDevolucoes();
      }
    }

    async function alterarEtapaVarios(devolucoes: IDTODevolucaoCompras[], proximaEtapa: boolean, cancelamento: boolean) {
      let retorno = {} as IRetornoRequisicao;
      const colunasAlteradas = [] as string[];
      const codigosDevolucoes = [] as number[];

      devolucoes.forEach((devolucao) => {
        let novaEtapa = 0;

        if (cancelamento) {
          novaEtapa = 4;
        } else if (proximaEtapa) {
          novaEtapa = devolucao.status + 1;
        } else {
          novaEtapa = devolucao.status - 1;
        }

        if (!colunasAlteradas.some((c) => c === String(devolucao.status))) {
          colunasAlteradas.push(String(devolucao.status));
        }
        if (!colunasAlteradas.some((c) => c === String(novaEtapa))) {
          colunasAlteradas.push(String(novaEtapa));
        }
        desmarcarSelecionado(devolucao.codigo, devolucao.status, Number(novaEtapa));
        codigosDevolucoes.push(devolucao.codigo);
      });
      retorno = await servicoDevolucaoCompras.alterarEtapaVarios(state.empresaSelecionada, proximaEtapa, cancelamento, codigosDevolucoes);
      if (retorno.status === 1) {
        state.devolucoes.forEach((d, index) => {
          if (cancelamento) {
            state.devolucoes[index].status = 4;
          } else if (proximaEtapa) {
            state.devolucoes[index].status += 1;
          } else {
            state.devolucoes[index].status -= 1;
          }
        });
      } else if (retorno.status === 2) {
        apresentarMensagemAlerta(retorno.mensagem);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
      if (state.visualizarKanban) {
        colunasAlteradas.forEach((c) => {
          recriarColuna(c);
        });
      } else {
        buscarDevolucoes();
      }
    }

    async function moverCard(card: ICardKanban, identificadorNovaColuna: string) {
      state.cardSelecionado = card.identificador;
      const indexDevolucao = state.devolucoes.findIndex((d) => d.codigo === Number(state.cardSelecionado));
      const devolucao = state.devolucoes[indexDevolucao];
      if (devolucao.status === EStatusDevolucaoCompras.Cancelada) {
        apresentarMensagemAlerta('A devolução informada está cancelada.');
        recriarColuna(identificadorNovaColuna);
        recriarColuna(String(devolucao.status));
        return;
      }
      Modal.confirm({
        title: `Realmente deseja passar da etapa ${obterTituloColuna(String(devolucao.status))} para a etapa ${obterTituloColuna(identificadorNovaColuna)}?`,
        content: `Devolução ${devolucao.numero} Nota ${devolucao.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(devolucao.dataEmissao)}.`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          await alterarEtapa(devolucao, identificadorNovaColuna);
        },
        onCancel: () => {
          recriarColuna(identificadorNovaColuna);
          recriarColuna(String(devolucao.status));
        },
      });
    }

    async function concluirEtapa() {
      let devolucao = {} as IDTODevolucaoCompras;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosConcluir === 0) {
        apresentarMensagemAlerta('Nenhuma devolução selecionada para conclusão de etapa.');
        return;
      } if (state.qtdSelecionadosConcluir === 1) {
        state.selecionados.forEach((s) => {
          const index = state.devolucoes.findIndex((d) => d.codigo === s);
          if (state.devolucoes[index].status < 3) {
            devolucao = state.devolucoes[index];
          }
        });
        titulo = `Realmente deseja passar da etapa ${obterTituloColuna(String(devolucao.status))} para a etapa ${obterTituloColuna(String(devolucao.status + 1))}?`;
        mensagem = `Devolução ${devolucao.numero} Nota ${devolucao.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(devolucao.dataEmissao)}.`;
      } else {
        titulo = `Realmente deseja alterar a etapa das ${state.qtdSelecionadosConcluir} devoluções selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosConcluir === 1) {
            await alterarEtapa(devolucao, String(devolucao.status + 1));
          } else {
            const devolucoes = [] as IDTODevolucaoCompras[];
            state.selecionados.forEach((s) => {
              const indexDevolucao = state.devolucoes.findIndex((r) => r.codigo === Number(s));
              if (state.devolucoes[indexDevolucao].status < 3) {
                devolucoes.push(state.devolucoes[indexDevolucao]);
              }
            });
            await alterarEtapaVarios(devolucoes, true, false);
          }
        },
      });
    }

    async function retomarEtapa() {
      let devolucao = {} as IDTODevolucaoCompras;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosRetomar === 0) {
        apresentarMensagemAlerta('Nenhuma devolução selecionada para retomada de etapa.');
        return;
      } if (state.qtdSelecionadosRetomar === 1) {
        state.selecionados.forEach((s) => {
          const index = state.devolucoes.findIndex((r) => r.codigo === s);
          if (state.devolucoes[index].status > 1 && state.devolucoes[index].status < 4) {
            devolucao = state.devolucoes[index];
          }
        });
        titulo = `Realmente deseja passar da etapa ${obterTituloColuna(String(devolucao.status))} para a etapa ${obterTituloColuna(String(devolucao.status - 1))}?`;
        mensagem = `Devolução ${devolucao.numero} Nota ${devolucao.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(devolucao.dataEmissao)}.`;
      } else {
        titulo = `Realmente deseja alterar a etapa das ${state.qtdSelecionadosConcluir} devolucões selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosRetomar === 1) {
            await alterarEtapa(devolucao, String(devolucao.status - 1));
          } else {
            const devolucoes = [] as IDTODevolucaoCompras[];
            state.selecionados.forEach((s) => {
              const indexDevolucao = state.devolucoes.findIndex((r) => r.codigo === Number(s));
              if (state.devolucoes[indexDevolucao].status > 1 && state.devolucoes[indexDevolucao].status < 4) {
                devolucoes.push(state.devolucoes[indexDevolucao]);
              }
            });
            await alterarEtapaVarios(devolucoes, false, false);
          }
        },
      });
    }

    async function cancelar() {
      let devolucao = {} as IDTODevolucaoCompras;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosCancelar === 0) {
        apresentarMensagemAlerta('Nenhuma devolução selecionada para o cancelamento.');
        return;
      } if (state.qtdSelecionadosCancelar === 1) {
        state.selecionados.forEach((s) => {
          const index = state.devolucoes.findIndex((d) => d.codigo === s);
          if (state.devolucoes[index].status < 4) {
            devolucao = state.devolucoes[index];
          }
        });
        titulo = 'Realmente deseja cancelar a devolução?';
        mensagem = `Devolução ${devolucao.numero} Nota ${devolucao.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(devolucao.dataEmissao)}.`;
      } else {
        titulo = `Realmente deseja cancelar as ${state.qtdSelecionadosConcluir} devoluções selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosCancelar === 1) {
            await alterarEtapa(devolucao, '4');
          } else {
            const devolucoes = [] as IDTODevolucaoCompras[];
            state.selecionados.forEach((s) => {
              const indexDevolucao = state.devolucoes.findIndex((d) => d.codigo === Number(s));
              if (state.devolucoes[indexDevolucao].status < 4) {
                devolucoes.push(state.devolucoes[indexDevolucao]);
              }
            });
            await alterarEtapaVarios(devolucoes, false, true);
          }
        },
      });
    }

    async function excluirDevolucoes(devolucao: IDTODevolucaoCompras[]) {
      const colunasAlteradas = [] as string[];
      const codigosDevolucoes = [] as number[];

      devolucao.forEach((devolucao) => {
        desmarcarSelecionado(devolucao.codigo, devolucao.status, devolucao.status);
        if (!colunasAlteradas.some((c) => c === String(devolucao.status))) {
          colunasAlteradas.push(String(devolucao.status));
        }
        codigosDevolucoes.push(devolucao.codigo);
      });

      const retorno = await servicoDevolucaoCompras.excluirVarios(state.empresaSelecionada, codigosDevolucoes);
      if (retorno.status === 1) {
        devolucao.forEach((devolucao) => {
          const indexDevolucao = state.devolucoes.findIndex((d) => d.codigo === devolucao.codigo);
          state.devolucoes.splice(indexDevolucao, 1);
        });
        colunasAlteradas.forEach((c) => {
          recriarColuna(c);
        });
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function excluir() {
      const devolucoes = [] as IDTODevolucaoCompras[];
      let devolucao = {} as IDTODevolucaoCompras;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosExcluir === 0) {
        apresentarMensagemAlerta('Nenhuma devolução selecionada para exclusão.');
        return;
      } if (state.qtdSelecionadosExcluir === 1) {
        state.selecionados.forEach((s) => {
          const index = state.devolucoes.findIndex((d) => d.codigo === s);
          if (state.devolucoes[index].status === 1) {
            devolucao = state.devolucoes[index];
          }
        });
        titulo = 'Realmente deseja excluir a devolução?';
        mensagem = `Devolução ${devolucao.numero} Nota ${devolucao.numeroNotaFiscal} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(devolucao.dataEmissao)}.`;
      } else {
        titulo = `Realmente deseja excluir as ${state.qtdSelecionadosExcluir} devoluções selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosExcluir === 1) {
            devolucoes.push(devolucao);
            await excluirDevolucoes(devolucoes);
          } else {
            state.selecionados.forEach((s) => {
              const indexDevolucao = state.devolucoes.findIndex((r) => r.codigo === Number(s));
              if (state.devolucoes[indexDevolucao].status === 1) {
                devolucoes.push(state.devolucoes[indexDevolucao]);
              }
            });
            await excluirDevolucoes(devolucoes);
          }
        },
      });
    }

    async function imprimirDetalhado(codgo: number) {
      telaBase.carregando = true;
      const retornoRelatorio = await servicoDevolucaoCompras.imprimirDevolucao(codgo, state.empresaSelecionada, ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function imprimirListagem(codigos: number[]) {
      const parametrosConsulta = {} as IParametrosConsultaDevolucaoCompras;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.numeroPagina = 1;
      parametrosConsulta.qtdeRegistrosPagina = 0;
      parametrosConsulta.qtdeRegistrosTotal = 0;
      parametrosConsulta.periodo = state.filtroPeriodo;
      parametrosConsulta.dataInicial = state.dataInicialSelecionada;
      parametrosConsulta.dataFinal = state.dataFinalSelecionada;
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.ordenacao.push('Status', 'Ordem');

      telaBase.carregando = true;
      const retornoRelatorio = await servicoDevolucaoCompras.relatorioPadrao(ETipoArquivo.PDF, parametrosConsulta, state.filtros, codigos);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function imprimir() {
      if (state.selecionados.length === 0) {
        apresentarMensagemAlerta('Nenhuma devolução selecionada para impressão!');
      } else if (state.selecionados.length === 1) {
        imprimirDetalhado(state.selecionados[0]);
      } else {
        imprimirListagem(state.selecionados);
      }
    }

    function verificaVisualizarDFe(codigoDevolucaoCompra: string): boolean {
      if (UtilitarioGeral.valorValido(codigoDevolucaoCompra)) {
        const devolucaoCompra = state.devolucoes.find((c) => c.codigo === Number(codigoDevolucaoCompra));
        if (devolucaoCompra !== undefined) {
          return servicoNotaFiscal.verificaVisualizarDFe(devolucaoCompra.modelo, devolucaoCompra.identificadorNotaFiscal, devolucaoCompra.statusNotaFiscal);
        }
      }
      return false;
    }

    async function visualizarDFe(codigoDevolucaoCompra: string) {
      if (UtilitarioGeral.valorValido(codigoDevolucaoCompra)) {
        const devolucaoCompra = state.devolucoes.find((c) => c.codigo === Number(codigoDevolucaoCompra));
        if (devolucaoCompra !== undefined) {
          const retorno = await servicoNotaFiscal.visualizar(devolucaoCompra.codigoNotaFiscal);
          if (retorno !== undefined) {
            if (retorno.sucesso) {
              if (UtilitarioGeral.valorValido(retorno.linkPdf)) {
                state.arquivosPdf = [{ nome: '', link: retorno.linkPdf } as IArquivoPdf];
              }
            } else if (UtilitarioGeral.valorValido(retorno.mensagem)) {
              apresentarMensagemAlerta(retorno.mensagem);
            }
          }
        }
      }
    }

    onBeforeMount(async () => {
      state.visualizarKanban = !UtilitarioDispositivo.larguraTelaMobile();
      state.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      telaBase.identificadorRecurso = state.identificadorRecurso;
      telaBase.identificadorPermissao = state.identificadorPermissao;
      preencherColunas();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([state.empresaSelecionada]);
      telaBase.propriedadesConsulta = await servicoDevolucaoCompras.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      state.dataAtual = await new ServicoSistema().obterDataAtual();
      await buscarDevolucoes();
    });

    function buscaRapidaDevolucao() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarDevolucoes();
      }, 600);
    }

    async function periodoAlterado() {
      if (state.filtroPeriodo !== EFiltroPeriodoMovimentoComercial.Personalizado) {
        await buscarDevolucoes();
      }
    }

    return {
      state,
      props,
      window,
      buscaRapidaDevolucao,
      EVinculoEmpresa,
      UtilitarioMascara,
      UtilitarioData,
      storeSistema,
      telaBase,
      incluir,
      editar,
      clickEditar,
      excluir,
      retomarEtapa,
      buscarDevolucoes,
      periodoAlterado,
      prencheDatasPeriodo,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      selecionarCard,
      concluirEtapa,
      moverCard,
      atualizarSelecionados,
      atualizarOrdemCards,
      EFiltroPeriodoMovimentoComercial,
      atualizarSelecionadosKanban,
      cancelar,
      imprimir,
      imprimirDetalhado,
      verificaVisualizarDFe,
      visualizarDFe,
    };
  },
});


import {
  computed, defineComponent, reactive,
} from 'vue';
import { ETipoDevolucaoPagamento } from '@/models/Enumeradores/Compras/ETipoDevolucaoPagamento';

export default defineComponent({
  name: 'SelecionarTipoDevolucaoPagamento',
  components: {
  },
  props: {
    valor: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    radioButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'change'],
  setup(props, { emit }) {
    const state = reactive({
      dica: '',
    });

    const computedValor = computed({
      get: () => props.valor,
      set: (valor: number) => {
        emit('update:valor', valor);
      },
    });

    function obterDica(valor: ETipoDevolucaoPagamento) {
      switch (valor) {
        case ETipoDevolucaoPagamento.Manter:
          state.dica = 'O lançamento de pagamento realizado no recebimento será completamente mantido.';
          break;
        case ETipoDevolucaoPagamento.LancarCredito:
          state.dica = 'Será lançado um crédito para o emitente do recebimento.';
          break;
        case ETipoDevolucaoPagamento.AbaterSaldo:
          state.dica = 'O valor devolvido será abatido no saldo das contas a pagar lançadas no recebimento.';
          break;
        case ETipoDevolucaoPagamento.LancarContasReceber:
          state.dica = 'Lançar contas a receber para o emitente do recebimento.';
          break;
        default:
          state.dica = '';
          break;
      }
    }

    function defineValorSelecionado(valor:number) {
      emit('update:valor', valor);
      emit('change', valor);
      obterDica(valor);
    }

    function defineValorMarcado(valor:number) {
      emit('change', valor);
    }

    return {
      props,
      state,
      computedValor,
      obterDica,
      defineValorSelecionado,
      defineValorMarcado,
    };
  },
});


import { defineComponent } from 'vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export default defineComponent({
  name: 'Totalizador',
  props: {
    titulo: {
      type: String,
    },
    monetario: {
      type: Boolean,
      default: false,
    },
    valor: {
      type: Number,
      default: 0,
    },
    casasDecimais: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    return {
      props,
      UtilitarioMascara,
    };
  },
});
